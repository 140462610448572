import React from "react";
import { BiTaxi } from "react-icons/bi";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={"/landing"} className="flex items-center gap-1 md:flex-row flex-col">
      <BiTaxi size={40} color="var(--primary-dark)" />
      <p className="font-bold text-lg text-[var(--primary-dark)] md:self-end">
        Pamseu IMS
      </p>
    </Link>
  );
};

export default Logo;

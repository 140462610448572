// Form.jsx
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { Loading } from "..";
import useApi from "../../hooks/useApi";
import { AuthContext } from "../../context/Contexts";

const Form = ({ item, closeModal, setData, tag }) => {
  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    isActive: true,
    role: "",
    password: "",
  });

  const insertApi = useApi();
  const updateApi = useApi();
  const { setAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (item) {
      setFormData({
        username: item.username || "",
        firstname: item.firstname || "",
        lastname: item.lastname || "",
        phone: item.phone || "",
        email: item.email || "",
        role: item.role || "",
        password: item.password || "",
        isActive: item.isActive || "",
      });
    }
  }, [item]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox correctly
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.username) formErrors.username = "Username is required";
    if (!formData.firstname) formErrors.firstname = "Firstname is required";
    if (!formData.lastname) formErrors.lastname = "Lastname is required";
    if (!formData.phone) formErrors.phone = "Phone number is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.role) formErrors.role = "Role is required";
    if (!formData.password) formErrors.password = "Password is required";
    return formErrors;
  };

  const handleInsert = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      await insertApi.refetch(
        `${apiUrl}/user`,
        "POST",
        formData,
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err.response ? err.response.data.error : err.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: `<b>User: ${data?.firstname} added successfully</b>`,
            });

            setData((prev) =>
              Array.isArray(prev)
                ? [
                    {
                      ...data,
                    },
                    ...prev,
                  ]
                : [data]
            );

            setErrors({});
            setFormData({
              username: "",
              firstname: "",
              lastname: "",
              phone: "",
              email: "",
              isActive: true,
              role: "",
              password: "",
            });
          }
        }
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      console.log(item);
      await updateApi.refetch(
        `${apiUrl}/user/${item.username}`,
        "PUT",
        formData,
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: "<b>User has been updated successfully</b>",
            });

            if (tag !== "User") {
              setData((prev) => {
                return prev.map((c) =>
                  c.username === item.username
                    ? {
                        ...c,
                        username: formData.username,
                        firstname: formData.firstname,
                        lastname: formData.lastname,
                        phone: formData.phone,
                        email: formData.email,
                        role: formData.role,
                        password: formData.password,
                        isActive: formData.isActive,
                      }
                    : c
                );
              });
            } else {
              setAuthUser((prev) => ({
                ...prev,
                username: formData.username,
                firstname: formData.firstname,
                lastname: formData.lastname,
                phone: formData.phone,
                email: formData.email,
                role: formData.role,
                password: formData.password,
                isActive: formData.isActive,
              }));
            }

            closeModal();
          }
        }
      );
    }
  };

  // Combined loading state
  const loading = insertApi.loading || updateApi.loading;

  return (
    <form className="w-full md:p-8 bg-white rounded">
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {item ? "Updating User" : "User Form"}
      </h2>

      {loading && <Loading />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        {!item && (
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="username">
              Username <span className="text-red-500">*</span>
            </label>
            <input
              id="username"
              name="username"
              type="text"
              value={formData.username}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.username ? "border-red-500" : "border-black"
              }`}
            />
            {errors.username && (
              <p className="text-red-500 text-xs mt-1">{errors.username}</p>
            )}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="firstname">
            Firstname <span className="text-red-500">*</span>
          </label>
          <input
            id="firstname"
            name="firstname"
            type="text"
            value={formData.firstname}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.firstname ? "border-red-500" : "border-black"
            }`}
          />
          {errors.firstname && (
            <p className="text-red-500 text-xs mt-1">{errors.firstname}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="lastname">
            Lastname <span className="text-red-500">*</span>
          </label>
          <input
            id="lastname"
            name="lastname"
            type="text"
            value={formData.lastname}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.lastname ? "border-red-500" : "border-black"
            }`}
          />
          {errors.lastname && (
            <p className="text-red-500 text-xs mt-1">{errors.lastname}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="phone">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            value={formData.phone}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.phone ? "border-red-500" : "border-black"
            }`}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email Address <span className="text-red-500">*</span>
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.email ? "border-red-500" : "border-black"
            }`}
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>
        {item?.role !== "admin" && tag !== "User" && (
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="role">
              User role <span className="text-red-500">*</span>
            </label>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.role ? "border-red-500" : "border-black"
              }`}
            >
              <option value="">Select role</option>
              <option value="Accountant">Accountant</option>
              <option value="Teacher">Teacher</option>
            </select>
            {errors.role && (
              <p className="text-red-500 text-xs mt-1">{errors.role}</p>
            )}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="password">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.password ? "border-red-500" : "border-black"
            }`}
          />
          {errors.password && (
            <p className="text-red-500 text-xs mt-1">{errors.password}</p>
          )}
        </div>
        {item?.role !== "admin" && tag !== "User" && (
          <div className="mb-4 flex gap-4 items-center self-center ml-4">
            <label className="block text-sm font-bold" htmlFor="isActive">
              Active:
            </label>
            <input
              id="active"
              name="isActive"
              type="checkbox"
              style={{ width: "25px", height: "25px" }}
              checked={formData.isActive}
              onChange={handleChange}
              className={`border rounded border-black `}
            />
          </div>
        )}
      </div>

      <div className="px-2 flex gap-6">
        {!item ? (
          <button
            type="button"
            onClick={handleInsert}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Submit"}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleUpdate}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Update"}
          </button>
        )}

        <div
          type="button"
          onClick={closeModal}
          className="mt-6 py-2 cursor-pointer px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default Form;

import React from 'react'

const StatisticCard = ({ name, value }) => {

  return (
    <div className="w-full flex justify-stretch items-center gap-7">
      <div className='p-2 md:text-[14pt] text-sm'>{name}</div>
      <div className='md:text-2xl text-lg p-2'>{value}</div>
    </div>
  );
};

export default StatisticCard;

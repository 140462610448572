import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { formattedAmount } from "../../utils/formattedAmount";
import { Loading } from "..";
import useApi from "../../hooks/useApi";

const Form = ({ item, closeModal }) => {
  const [formData, setFormData] = useState({
    amount: "",
    mirage: "",
    ref: "",
    mode: "",
    dateOfPayment: "",
    receiptNo: "",
  });

  const insertApi = useApi();
  const updateApi = useApi();

  useEffect(() => {
    if (item) {
      setFormData({
        id: item.id || "",
        amount: item.amount || "",
        mode: item.mode || "",
        mirage: item.mirage || "",
        ref: item.ref || "",
        dateOfPayment: item.dateOfPayment || "",
        receiptNo: item.receiptNo || "",
      });
    }
  }, []);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (name === "amount") {
      // Remove any commas from the value
      updatedValue = value.replace(/,/g, "");
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const validate = () => {
    let formErrors = {};

    // Check if dateOfPayment is provided
    if (!formData.dateOfPayment) {
      formErrors.dateOfPayment = "Date of payment is required";
    } else {
      // Parse the input date and remove time component
      const inputDate = new Date(formData.dateOfPayment);
      inputDate.setHours(0, 0, 0, 0); // Normalize the time to 00:00:00

      // Get today's date and remove the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

      // Check if the input date is in the future (but allow today)
      if (inputDate > today) {
        formErrors.dateOfPayment = "Date of payment cannot be in the future";
      }
    }

    if (!formData.receiptNo)
      formErrors.receiptNo = "Receipt number is required";
    if (!formData.mode) formErrors.mode = "Mode of payment is required";
    if (!formData.mirage) formErrors.mirage = "Description is required";
    if (formData.amount) {
      if (isNaN(formData.amount) || formData.amount <= 0) {
        formErrors.amount = "Amount must be a valid number and greater than 0";
      }
    } else {
      formErrors.amount = "Amount is required";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      if (submitButtonText === "Submit") {
        await insertApi.refetch(
          `${apiUrl}/income`,
          "POST",
          formData,
          { headers: { loginToken: localStorage.getItem("loginToken") } },
          (err, data) => {
            if (err) {
              if (err.response) {
                Swal.fire({
                  title: "Form Error!",
                  html: err.response.data.error,
                  icon: "error",
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                  width: "400px",
                  customClass: {
                    popup: "small-swal",
                  },
                });
              } else {
                Swal.fire({
                  title: "Form Error!",
                  html: err.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                  width: "400px",
                  customClass: {
                    popup: "small-swal",
                  },
                });
              }
            } else {
              Swal.fire({
                title: "Success",
                icon: "success",
                width: "400px",
                customClass: {
                  popup: "small-swal",
                },
                html: `<b>Receipt: ${data?.receiptNo} added successfully</b><br>Please refresh the data`,
              });

              setErrors({});
              // Optionally reset form data
              setFormData({
                amount: "",
                mirage: "",
                ref: "",
                mode: "",
                dateOfPayment: "",
                receiptNo: "",
              });
            }
          }
        );
      } else if (submitButtonText === "Update") {
        await updateApi.refetch(
          `${apiUrl}/income/${item.id}`,
          "PUT",
          formData,
          {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          },
          (err, data) => {
            if (err) {
              Swal.fire({
                title: "Form Error!",
                html: err,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false,
                width: "400px",
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Success",
                icon: "success",
                width: "400px",
                customClass: {
                  popup: "small-swal",
                },
                html: "<b>Income has been updated successfully</b> <br>Please refresh the data",
              });

              closeModal();
            }
          }
        );
      }
    }
  };

  // Combined loading state
  const loading = insertApi.loading || updateApi.loading;

  return (
    <form className="w-full bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {item ? "Updating Income" : "Other Income Form"}
      </h2>

      {loading && <Loading />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="receiptNo">
            Receipt Number <span className="text-red-500">*</span>
          </label>
          <input
            id="receiptNo"
            name="receiptNo"
            type="text"
            value={formData.receiptNo}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.receiptNo ? "border-red-500" : "border-black"
            }`}
          />
          {errors.receiptNo && (
            <p className="text-red-500 text-xs mt-1">{errors.receiptNo}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="dateOfPayment"
          >
            Date of Payment <span className="text-red-500">*</span>
          </label>
          <input
            id="dateOfPayment"
            name="dateOfPayment"
            type="date"
            value={formData.dateOfPayment}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dateOfPayment ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dateOfPayment && (
            <p className="text-red-500 text-xs mt-1">{errors.dateOfPayment}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="mirage">
            Description <span className="text-red-500">*</span>
          </label>
          <input
            id="mirage"
            name="mirage"
            type="text"
            value={formData.mirage}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.mirage ? "border-red-500" : "border-black"
            }`}
          />
          {errors.mirage && (
            <p className="text-red-500 text-xs mt-1">{errors.mirage}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="mode">
            Mode of Payment <span className="text-red-500">*</span>
          </label>
          <select
            id="mode"
            name="mode"
            value={formData.mode}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.mode ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Mode of Payment</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="From other Banks">From other Banks</option>
            <option value="Mo626">Mo626</option>
            <option value="Direct Banking">Direct Banking</option>
          </select>
          {errors.mode && (
            <p className="text-red-500 text-xs mt-1">{errors.mode}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="amount">
            Amount received <span className="text-red-500">*</span>
          </label>
          <input
            id="amount"
            name="amount"
            type="text"
            value={formattedAmount(formData.amount.toString())}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.amount ? "border-red-500" : "border-black"
            }`}
          />
          {errors.amount && (
            <p className="text-red-500 text-xs mt-1">{errors.amount}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="ref">
            Reference
          </label>
          <input
            id="ref"
            name="ref"
            type="text"
            value={formData.ref}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.reference ? "border-red-500" : "border-black"
            }`}
          />
        </div>
      </div>

      <div className=" flex gap-2 justify-evenly">
        <button
          type="submit"
          disabled={loading}
          className={`mt-6 w-full ${
            loading ? "cursor-not-allowed" : ""
          } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
        >
          {item ? (
            <div> {loading ? "Processing ..." : "Update"}</div>
          ) : (
            <div> {loading ? "Processing ..." : "Submit"}</div>
          )}
        </button>

        <div
          type="button"
          disabled={loading}
          onClick={closeModal}
          className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default Form;

export const formattedAmount = (value) => {
  // Check if value is defined and not null
  if (value !== undefined && value !== null) {
    // Remove commas from the input value
    const cleanValue = value.replace(/,/g, "");
    // Parse the cleaned value as a float
    const parsedValue = parseFloat(cleanValue);
    // If parsing is successful and the value is not NaN, return formatted value
    if (!isNaN(parsedValue)) {
      return parsedValue.toLocaleString();
    }
  }
  // Otherwise, return the original input value
  return value;
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import {
  AllocationForm,
  AllocationReport,
  AllocationTable,
  Loading,
  Modal,
} from "../components";
import generateTimeRanges from "../utils/timeRange";
import Swal from "sweetalert2";

const VehicleAllocation = () => {
  const vehicleApi = useApi();
  const studentApi = useApi();
  const allocApi = useApi();
  const [vehicles, setVehicles] = useState([]);
  const [students, setStudents] = useState([]);
  const [tempStudents, setTempStudents] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [allocDate, setAllocDate] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const timeRanges = generateTimeRanges("06:00", "17:30", 30);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        vehicleApi.refetch(`${apiUrl}/vehicles`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
        studentApi.refetch(`${apiUrl}/students`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
      ]);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (vehicleApi.data) {
      setVehicles(
        vehicleApi.data.map((item) => ({
          value: item.instructor,
          label: item.regNo,
        }))
      );
    }

    if (studentApi.data) {
      setStudents(
        studentApi.data.map((item) => ({
          value: item.studentID,
          label: item.fullname,
        }))
      );
      setTempStudents(
        studentApi.data.map((item) => ({
          value: item.fullname,
          label: item.fullname,
        }))
      );
    }
  }, [vehicleApi.data, studentApi.data]);

  const handleVehicleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedVehicle({
        regNo: selectedOption.label,
        instructor: selectedOption.value,
      });
    }
  };

  const handleStudentChange = (selectedOption) => {
    console.log("Selected:", selectedOption);
  };

  const initiate = () => {
    if (Object.keys(selectedVehicle).length > 0) {
      allocApi.refetch(
        `${apiUrl}/allocation/${selectedVehicle.regNo}`,
        "GET",
        null,
        {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }
      );
    } else {
      Swal.fire({
        title: "Input error",
        text: "Please select vehicle",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    }
  };

  const handleAllocDate = (e) => {
    setAllocDate(e.target.value);
  };

  const handlePrint = () => {
    if (allocDate && allocApi.data) {
      setModalContent(
        <AllocationReport
          data={allocApi.data}
          allocDate={allocDate}
          closeModal={closeModal}
        />
      );
      openModal();
    } else {
      Swal.fire({
        title: "Input error",
        text: "Please select allocation date and vehicle before you print",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    }
  };

  const loading = vehicleApi.loading || studentApi.loading;
  const error = studentApi.error || vehicleApi.error;

  return (
    <div className="w-full flex  h-full flex-col items-center gap-3">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />

      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Vehicle Allocation
      </div>
      <div className="text-lg font-bold mt-[20px]">Manage Allocations</div>
      <hr className="my-4 w-full" />

      {loading ? (
        <Loading />
      ) : error ? (
        <div className="text-lg text-center w-full text-red-500 animate-pulse">
          {error}
        </div>
      ) : (
        <div className="flex md:flex-row flex-col w-full md:gap-2 gap-5 p-1 overflow-auto h-full">
          <AllocationForm
            loading={loading}
            handleVehicleChange={handleVehicleChange}
            handleStudentChange={handleStudentChange}
            vehicles={vehicles}
            students={students}
            timeRanges={timeRanges}
            initiate={initiate}
          />
          <AllocationTable
            selectedVehicle={selectedVehicle}
            data={allocApi.data}
            loading={allocApi.loading}
            error={allocApi.error}
            students={students}
            tempStudents={tempStudents}
            allocDate={allocDate}
            handleAllocDate={handleAllocDate}
            handlePrint={handlePrint}
            initiate={initiate}
          />
        </div>
      )}
    </div>
  );
};

export default VehicleAllocation;

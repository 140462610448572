import React, { useState } from "react";
import { toKwacha } from "../../utils/toKwacha";
import { HiInformationCircle } from "react-icons/hi";
import { BiEdit, BiSearch, BiTrash } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import { DurationAdd, Modal } from "..";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../utils/links";

const Cards = ({ durations, setDurations, setLoading }) => {
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addDuration = (data) => {
    setModalContent(
      <DurationAdd closeModal={closeModal} setDurations={setDurations} />
    );
    openModal();
  };

  const updateDuration = (duration) => {
    setModalContent(
      <DurationAdd
        duration={duration}
        closeModal={closeModal}
        setDurations={setDurations}
      />
    );
    openModal();
  };

  // Search handler for input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter durations by duration.days or duration.name
  const filteredDurations = durations.filter((duration) =>
    duration?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = (duration) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${duration?.name}?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .delete(`${apiUrl}/durations/${duration.id}`, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          })
          .then((res) => {
            setDurations((prev) => prev.filter((c) => c.id !== duration.id));
            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Duration has been successfully deleted.",
              icon: "success",
              timer: 2000,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
              showConfirmButton: false, // Automatically close after 2 seconds
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              Swal.fire({
                title: "Error!",
                text: err.response.data.error,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            }
          });
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="flex w-full md:justify-between md:flex-row flex-col-reverse gap-2 items-center">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ..."
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>

        <div className="text-gray-400 font-semibold">
          (~{filteredDurations?.length}~) Records
        </div>

        <div
          className="px-4 py-2 rounded-lg bg-gray-500 text-white flex items-center gap-1 cursor-pointer"
          onClick={addDuration}
        >
          <CgAdd size={20} />
          Add duration
        </div>
      </div>
      {filteredDurations?.length > 0 ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
          {filteredDurations.map((duration) => (
            <div
              key={duration.id}
              className="p-3 border-2 shadow-lg rounded-xl flex flex-col justify-between items-center gap-3 relative"
            >
              <div className="absolute top-0 left-0 m-2">
                <p className="text-sm text-gray-300">ID: {duration.id}</p>
              </div>
              <h1 className="text-xl mt-2 font-semibold border-b-[2px] border-gray-300 p-2 w-full text-center">
                {duration.days} days
              </h1>
              <p>{duration.name}</p>

              <div className="flex justify-center gap-[35px] border-t-[1px] border-red-200 w-full items-center p-1 mt-2">
                <BiEdit
                  size={20}
                  title="Update duration"
                  className="cursor-pointer"
                  onClick={() => updateDuration(duration)}
                />
                <BiTrash
                  size={20}
                  color="red"
                  title="Delete duration"
                  className="cursor-pointer"
                  onClick={() => handleDelete(duration)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="m-auto max-w-full text-xl mt-5 font-semibold uppercase">
          No durations found !
        </div>
      )}
    </div>
  );
};

export default Cards;

import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";

const Paginated = ({ data, setDurations, setLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState();

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    const firstname = item?.User?.firstname
      ? item.User.firstname.toString().toLowerCase()
      : "";
    const lastname = item?.User?.lastname
      ? item.User.lastname.toLowerCase()
      : "";
    const time = item.date ? item.date.toString().toLowerCase() : "";

    return (
      firstname.includes(searchTerm.toLowerCase()) ||
      lastname.includes(searchTerm.toLowerCase()) ||
      time.includes(searchTerm.toLowerCase())
    );
  });

  // Update the count whenever currentRecords changes
  useEffect(() => {
    setCount(filteredData.length);
  }, [filteredData]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="h-full pl-[10px] w-full mt-[30px]">
      <div className="text-gray-400 mx-4 my-1 font-bold">
        ~{`${count} Record(s)`}~
      </div>
      {/* Search and Records per Page */}
      <div className="flex items-center md:gap-1 w-full lg:justify-between gap-4 md:justify-evenly justify-evenly md:flex-row flex-col">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ... "
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>
        <div className="md:mr-5 mr-1 flex md:ml-1 ml-8 md:pr-4">
          <label className="text-sm font-semibold">
            Records per page:
            <input
              type="number"
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              min={1}
              className="ml-2 p-1 border border-black rounded max-w-[100px] pl-3"
            />
          </label>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto mt-4 md:pr-2 pr-1">
        <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="px-4 py-2">User</th>
              <th className="px-4 py-2">Phone</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Logged on</th>
              <th className="px-4 py-2">AT</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((item, index) => (
              <tr key={index} className="text-center hover:bg-slate-200">
                <td className="px-4 py-2">
                  {item?.User?.firstname + " " + item?.User?.lastname}
                </td>
                <td className="px-4 py-2">{item?.User?.phone}</td>
                <td className="px-4 py-2">{item?.User?.email}</td>
                <td className="px-4 py-2">{item?.date.split("T")[0]}</td>
                <td className="px-4 py-2">
                  {item?.date.split("T")[1].split(".")[0]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex flex-wrap gap-3 items-center mb-[20px] justify-center w-full mt-3 border-t-2 py-2">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          First
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          Last
        </button>
      </div>
    </div>
  );
};

export default Paginated;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/links";
import Swal from "sweetalert2";
import { formattedAmount } from "../../utils/formattedAmount";
import { toKwacha } from "../../utils/toKwacha";
import Loading from "../Loading";

const StudentAdd = ({ activeTab, setActiveTab }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    postalAddress: "",
    gender: "",
    trn: "",
    dateRegistered: "",
    anyDiscount: false,
    duration: "",
    anyGovernmentFee: false,
    anyAssesmentFee: false,
    course: "",
    guardianName: "",
    guardianPhone: "",
    physicalAddress: "",
    discountAmount: 0,
    graduated: false,
  });

  const [studentID, setStudentID] = useState(null);

  useEffect(() => {
    if (activeTab.data) {
      setStudentID(activeTab.data.studentID);
      setLoading(true);
      setFormData({
        fullname: activeTab.data?.fullname,
        phone: activeTab.data?.phone,
        postalAddress: activeTab.data?.postalAddress,
        gender: activeTab.data?.gender,
        trn: activeTab.data?.trn,
        dateRegistered: activeTab.data?.dateRegistered,
        anyDiscount: activeTab.data?.anyDiscount === 1 ? true : false,
        duration: activeTab.data?.duration,
        anyGovernmentFee: activeTab.data?.anyGovernmentFee === 1 ? true : false,
        anyAssesmentFee: activeTab.data?.anyAssesmentFee === 1 ? true : false,
        course: activeTab.data?.course,
        guardianName: activeTab.data?.StudentNextOfKins[0]?.name,
        guardianPhone: activeTab.data?.StudentNextOfKins[0]?.phone,
        physicalAddress: activeTab.data?.StudentNextOfKins[0]?.physicalAddress,
        discountAmount: activeTab.data?.Discount?.amount || 0,
        graduated: activeTab.data?.graduated === 1 ? true : false,
      });

      loadDurationsFor(activeTab.data?.course);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [courses, setCourses] = useState([]); // Options for courses
  const [durations, setDurations] = useState([]); // Options for durations

  // Fetch courses and durations from another table (simulated with dummy data)
  useEffect(() => {
    setLoading(true);
    loadCourses();
  }, []);

  const loadCourses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/courses`, {
        headers: { loginToken: localStorage.getItem("loginToken") },
      });

      if (response.data) {
        setCourses(response.data);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
      } else {
        console.log(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let updatedValue = type === "checkbox" ? checked : value;

    if (name === "discountAmount") {
      // Remove any commas from the value
      updatedValue = value.replace(/,/g, "");
    }

    if (name === "course") {
      setLoading(true);
      loadDurationsFor(value);
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const loadDurationsFor = async (course) => {
    try {
      const response = await axios.get(`${apiUrl}/courses/${course}`, {
        headers: { loginToken: localStorage.getItem("loginToken") },
      });

      if (response.data) {
        setDurations(response.data?.CourseDurations);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
      } else {
        console.log(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.fullname) formErrors.fullname = "Fullname is required";
    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\+?\d{1,14}$/.test(formData.phone)) {
      formErrors.phone = "Invalid phone number (e.g +265996570252)";
    }

    // Check if dateRegistered is provided
    if (!formData.dateRegistered) {
      formErrors.dateRegistered = "Date is required";
    } else {
      // Parse the input date and remove time component
      const inputDate = new Date(formData.dateRegistered);
      inputDate.setHours(0, 0, 0, 0); // Normalize the time to 00:00:00

      // Get today's date and remove the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

      // Check if the input date is in the future (but allow today)
      if (inputDate > today) {
        formErrors.dateRegistered =
          "Date of registration cannot be in the future";
      }
    }
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.course) formErrors.course = "Course is required";
    if (!formData.duration) formErrors.duration = "Duration is required";
    if (formData.anyDiscount) {
      if (isNaN(formData.discountAmount) || formData.discountAmount <= 0) {
        formErrors.discountAmount =
          "Discount amount must be a valid number and greater than 0";
      }
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);

      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(`${apiUrl}/students`, formData, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          });

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>${response.data?.newStudent?.fullname} added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            fullname: "",
            phone: "",
            gender: "",
            dateRegistered: "",
            postalAddress: "",
            anyDiscount: false,
            duration: "",
            trn: "",
            anyGovernmentFee: false,
            anyAssesmentFee: false,
            course: "",
            guardianName: "",
            guardianPhone: "",
            physicalAddress: "",
            discountAmount: 0,
            graduated: false, // Reset graduated field
          });
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/students/${studentID}`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Student has been updated successfully</b>",
          });

          setActiveTab({ tab: 2, data: activeTab.data });
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <div className="flex md:justify-between items-center md:flex-nowrap flex-wrap">
        <h2 className="md:text-2xl text-lg font-semibold mb-6">
          {activeTab.data ? "Updating Student" : "Student Registration"}
        </h2>
      </div>

      {loading && <Loading />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="fullname">
            Fullname <span className="text-red-500">*</span>
          </label>
          <input
            id="fullname"
            name="fullname"
            type="text"
            placeholder="e.g Mike Mhango"
            value={formData.fullname}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.fullname ? "border-red-500" : "border-black"
            }`}
          />
          {errors.fullname && (
            <p className="text-red-500 text-xs mt-1">{errors.fullname}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="gender">
            Gender <span className="text-red-500">*</span>
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.gender ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors.gender && (
            <p className="text-red-500 text-xs mt-1">{errors.gender}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="phone">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="e.g +265996570252"
            value={formData.phone}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.phone ? "border-red-500" : "border-black"
            }`}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="postalAddress"
          >
            Residential Address
          </label>
          <input
            id="postalAddress"
            name="postalAddress"
            placeholder="e.g Mchesi, Lilongwe"
            type="text"
            value={formData.postalAddress}
            onChange={handleChange}
            className="w-full p-2 border rounded border-black"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="dateRegistered"
          >
            Date Registered <span className="text-red-500">*</span>
          </label>
          <input
            id="dateRegistered"
            name="dateRegistered"
            type="date"
            value={formData.dateRegistered}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dateRegistered ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dateRegistered && (
            <p className="text-red-500 text-xs mt-1">{errors.dateRegistered}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="trn">
            TRN
          </label>
          <input
            id="trn"
            name="trn"
            placeholder="Traffic Register Number"
            type="text"
            value={formData.trn}
            onChange={handleChange}
            className="w-full p-2 border rounded border-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="course">
            Course <span className="text-red-500">*</span>
          </label>
          <select
            id="course"
            name="course"
            value={formData.course}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.course ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Course</option>
            {courses.map((course, idx) => (
              <option key={idx} value={course.code}>
                {course.code}
              </option>
            ))}
          </select>
          {errors.course && (
            <p className="text-red-500 text-xs mt-1">{errors.course}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="duration">
            Duration <span className="text-red-500">*</span>
          </label>
          <select
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.duration ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Duration</option>
            {durations.map((duration, idx) => (
              <option key={idx} value={duration?.Duration.name}>
                {duration?.Duration.name + "~" + toKwacha(duration?.fees)}
              </option>
            ))}
          </select>
          {errors.duration && (
            <p className="text-red-500 text-xs mt-1">{errors.duration}</p>
          )}
        </div>

        {/* Guardian Information */}
        <div className="mb-4 col-span-1 md:col-span-2 lg:col-span-3 grid  gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <h3 className="text-lg font-semibold mb-2 lg:col-span-3 md:col-span-2 col-span-1">
            Guardian Information
          </h3>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="guardianName"
            >
              Guardian Name
            </label>
            <input
              id="guardianName"
              name="guardianName"
              type="text"
              value={formData.guardianName}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.guardianName ? "border-red-500" : "border-black"
              }`}
            />
            {errors.guardianName && (
              <p className="text-red-500 text-xs mt-1">{errors.guardianName}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="guardianPhone"
            >
              Guardian Phone
            </label>
            <input
              id="guardianPhone"
              name="guardianPhone"
              type="text"
              value={formData.guardianPhone}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.guardianPhone ? "border-red-500" : "border-black"
              }`}
            />
            {errors.guardianPhone && (
              <p className="text-red-500 text-xs mt-1">
                {errors.guardianPhone}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="physicalAddress"
            >
              Physical Address
            </label>
            <input
              id="physicalAddress"
              name="physicalAddress"
              type="text"
              value={formData.physicalAddress}
              onChange={handleChange}
              className="w-full p-2 border rounded border-black"
            />
          </div>
        </div>

        {/* Checkbox Fields */}
        <div className="mb-4 col-span-1 md:col-span-2 lg:col-span-3">
          <div className="flex items-center mb-2">
            <input
              id="anyGovernmentFee"
              name="anyGovernmentFee"
              type="checkbox"
              checked={formData.anyGovernmentFee}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="anyGovernmentFee">Any Government Fee</label>
          </div>

          <div className="flex items-center mb-2">
            <input
              id="anyAssesmentFee"
              name="anyAssesmentFee"
              type="checkbox"
              checked={formData.anyAssesmentFee}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="anyAssesmentFee">Any Assessment Fee</label>
          </div>

          <div className="mb-2 col-span-1 md:col-span-2 lg:col-span-3">
            <div className="flex items-center mb-2">
              <input
                id="graduated"
                name="graduated"
                type="checkbox"
                checked={formData.graduated}
                onChange={handleChange}
                className="mr-2"
              />
              <label htmlFor="graduated">Graduated</label>
            </div>
          </div>

          <div className="flex items-center mb-2">
            <input
              id="anyDiscount"
              name="anyDiscount"
              type="checkbox"
              checked={formData.anyDiscount}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="anyDiscount">Any Discount</label>
          </div>
        </div>

        {/* Conditional Discount Amount Field */}
        {formData.anyDiscount && (
          <div className="mb-4 col-span-1 md:col-span-2 lg:col-span-3">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="discountAmount"
            >
              Discount Amount
            </label>
            <input
              id="discountAmount"
              name="discountAmount"
              type="text"
              value={formattedAmount(formData?.discountAmount.toString())}
              onChange={handleChange}
              className="w-full p-2 border rounded border-black"
            />
            {errors.discountAmount && (
              <p className="text-red-500 text-xs mt-1">
                {errors.discountAmount}
              </p>
            )}
          </div>
        )}
      </div>

      <button
        type="submit"
        disabled={loading}
        className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
      >
        {activeTab.data ? (
          <div> {loading ? "Processing ..." : "Update"}</div>
        ) : (
          <div> {loading ? "Processing ..." : "Submit"}</div>
        )}
      </button>
    </form>
  );
};

export default StudentAdd;

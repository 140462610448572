// Form.jsx
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { Loading } from "..";
import useApi from "../../hooks/useApi";
import { AuthContext } from "../../context/Contexts";

const Form = ({ item, closeModal, setData }) => {
  const { authUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: "",
  });

  const insertApi = useApi();
  const updateApi = useApi();

  useEffect(() => {
    if (item) {
      setFormData({
        id: item.id || "",
        name: item.name || "",
      });
    }
  }, [item]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Expense name is required";
    return formErrors;
  };

  const handleInsert = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      await insertApi.refetch(
        `${apiUrl}/expenses`,
        "POST",
        formData,
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err.response ? err.response.data.error : err.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: `<b>Expense: ${data?.name} added successfully</b>`,
            });

            setData((prev) =>
              Array.isArray(prev)
                ? [
                    {
                      ...data,
                      User: {
                        firstname: authUser.firstname,
                        lastname: authUser.lastname,
                      },
                    },
                    ...prev,
                  ]
                : [data]
            );

            setErrors({});
            setFormData({
              name: "",
            });
          }
        }
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      await updateApi.refetch(
        `${apiUrl}/expenses/${item.id}`,
        "PUT",
        formData,
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: "<b>Expense has been updated successfully</b>",
            });

            setData((prev) => {
              return prev.map((c) =>
                c.id === item.id ? { ...c, name: formData.name } : c
              );
            });

            closeModal();
          }
        }
      );
    }
  };

  // Combined loading state
  const loading = insertApi.loading || updateApi.loading;

  return (
    <form className="w-full md:p-8 bg-white rounded">
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {item ? "Updating Expense" : "Expense Form"}
      </h2>

      {loading && <Loading />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="name">
            Expense name <span className="text-red-500">*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.name ? "border-red-500" : "border-black"
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-xs mt-1">{errors.name}</p>
          )}
        </div>
      </div>

      <div className="px-2 flex gap-6">
        {!item ? (
          <button
            type="button"
            onClick={handleInsert}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Submit"}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleUpdate}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Update"}
          </button>
        )}

        <div
          type="button"
          onClick={closeModal}
          className="mt-6 py-2 cursor-pointer px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default Form;

import React, { useContext, useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/links";
import { AuthContext } from "../context/Contexts";
import { Logo } from "../components";

const Login = () => {
  // State for form fields
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { authUser, setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser.loginStatus) {
      navigate("/");
    }
  }, [authUser.loginStatus, navigate]);

  // Update form field values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  // Form validation
  const validateForm = () => {
    const { username, password } = credentials;
    if (!username || !password) {
      setError("Username and password are required.");
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message

    // Validate inputs
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/login`, credentials);
      localStorage.setItem("loginToken", response.data);

      await fetchAuthenticatedUser();
      navigate("/");
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch authenticated user
  const fetchAuthenticatedUser = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/authenticatedUser`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });
      setAuthUser((prevAuthUser) => ({
        ...prevAuthUser,
        ...response.data.user,
        loginStatus: response.data.login_status,
        ait: response.data.iat,
      }));
    } catch (err) {
      console.error("Verification error:", err);
    }
  };

  // Handle errors
  const handleError = (error) => {
    const errorMessage = error.response
      ? error.response.data.error
      : error.message;
    setError(errorMessage);
  };

  return (
    <div className="max-w-[1300px] m-auto flex flex-col h-screen">
      <div className="mt-6">
        <Logo />
      </div>

      <div className="flex mx-auto my-[150px] p-2">
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">Log in to Pamseu IMS</h1>

          {error && (
            <p className="max-w-[375px] overflow-auto text-red-500 mt-4 border-x-2 border-red-300 px-2 rounded-lg py-1 animate-pulse">
              {error}
            </p>
          )}

          <div className="mt-[40px] relative">
            <AiOutlineUser size={18} className="absolute top-[11px] left-3" />
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={credentials.username}
              onChange={handleInputChange}
              className="border-[1px] min-w-[350px] border-black/40 p-[7px] rounded-md pl-9"
            />
          </div>

          <div className="mt-[25px] relative">
            <BiLock size={18} className="absolute top-[11px] left-3" />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={credentials.password}
              onChange={handleInputChange}
              className="border-[1px] min-w-[350px] border-black/40 p-[7px] rounded-md pl-9"
            />
          </div>

          <Link to="#" className="mt-2 text-sm text-gray-500">
            Forgot your password?
          </Link>

          <button
            type="submit"
            className="mt-[30px] min-w-[340px] bg-[var(--primary-dark)] text-white py-2 rounded-md"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Log in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

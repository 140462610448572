import React from 'react'

const Card = ({title, children}) => {
  return (
    <div className='min-w-[275px] flex flex-col shadow-md rounded-sm'>
        <h1 className="md:text-lg text-md font-bold w-full p-3 bg-[var(--primary-dark)] text-white rounded-md">{title}</h1>
        <div className='md:p-2 p-1'>
            {children}
        </div>
    </div>
  )
}

export default Card

import React, { useEffect, useState } from "react";
import { toKwacha } from "../../utils/toKwacha";
import { BiEdit, BiPrinter, BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../utils/links";
import Loading from "../Loading";
import { Modal, RTFeesAdd } from "..";
import { CgAdd } from "react-icons/cg";
import Report from "./Report";

const StudentReceipts = ({ student, setStudent, fetchFullInfo }) => {
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleReport = (receipt) => {
    setModalContent(
      <Report
        data={receipt}
        student={student.student}
        closeModal={closeModal}
      />
    );
    openModal();
  };

  const newReceipt = () => {
    if (student?.anyGovernmentFee) {
      setModalContent(
        <RTFeesAdd student={student.student} closeModal={closeModal} />
      );
      openModal();
    } else if (student?.student?.anyGovernmentFee) {
      setModalContent(
        <RTFeesAdd student={student.student} closeModal={closeModal} />
      );
      openModal();
    } else {
      Swal.fire({
        title: "Unable to make payment",
        text: "This student has no government fee (Self Paid to Road Traffic).",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    }
  };

  const edit = (receipt) => {
    setModalContent(
      <RTFeesAdd
        student={student.student}
        closeModal={closeModal}
        receipt={receipt}
      />
    );
    openModal();
  };

  const del = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete receipt  (${id})?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .delete(`${apiUrl}/gvt/${id}`, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          })
          .then((res) => {
            setStudent((prevStudent) => ({
              ...prevStudent,
              student: {
                ...prevStudent.student,
                Receipts: prevStudent.student.Receipts.filter(
                  (receipt) => receipt.id !== id
                ),
              },
            }));

            fetchFullInfo && fetchFullInfo(student.student.studentID);

            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: `${id} has been successfully deleted.`,
              icon: "success",
              timer: 2000,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
              showConfirmButton: false, // Automatically close after 2 seconds
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              Swal.fire({
                title: "Error!",
                text: err.response.data.error,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            }
          });
      }
    });
  };
  return (
    <div className="mb-4 mt-[40px] flex flex-col gap-1 mx-auto container">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <h3 className="text-xl font-semibold mx-1">Traffic Fees History</h3>
      <div
        className="self-end px-4 py-2 bg-gray-600 text-sm font-bold rounded-xl text-white cursor-pointer flex items-center gap-1"
        onClick={newReceipt}
      >
        <CgAdd size={20} />
        New Payment
      </div>
      {loading && <Loading />}

      <div className="w-full overflow-auto">
        {student?.student?.Governments?.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 mt-2">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2">Date of Payment</th>
                <th className="border px-4 py-2">Receipt No</th>
                <th className="border px-4 py-2">Mode of Payment</th>
                <th className="border px-4 py-2">Amount</th>
                <th className="border px-4 py-2">Payment For</th>
                <th className="border px-4 py-2">Reference</th>
                <th className="border px-4 py-2">Cheque No.</th>
                <th className="border px-4 py-2">Added by</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {student.student.Governments.map((receipt) => (
                <tr key={receipt.id}>
                  <td className="border px-4 py-2">{receipt.dateOfPayment}</td>
                  <td className="border px-4 py-2">
                    {receipt.receiptNo || "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {receipt.modeOfPayment || "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {toKwacha(receipt.amount)}
                  </td>
                  <td className="border px-4 py-2">
                    {receipt.paymentOf || "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {receipt.reference || "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {receipt.chequeNo || "N/A"}
                  </td>
                  <td className="border px-4 py-2">{receipt.user || "N/A"}</td>
                  <td className="border px-4 py-2 flex flex-wrap gap-3">
                    <BiPrinter
                      title="Print receipt"
                      className="cursor-pointer"
                      onClick={() => handleReport(receipt)}
                      size={20}
                    />
                    <BiEdit
                      title="Update record"
                      className="cursor-pointer"
                      size={20}
                      onClick={() => edit(receipt)}
                    />
                    <BiTrash
                      title="Delete record"
                      size={20}
                      className="text-red-600 cursor-pointer"
                      onClick={() => del(receipt.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No fees receipts available.</p>
        )}
      </div>
    </div>
  );
};

export default StudentReceipts;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import axios from "axios";

const DurationAdd = ({ duration, closeModal, setDurations }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    days: "",
    name: "",
  });

  useEffect(() => {
    duration &&
      setFormData({
        id: duration.id,
        days: duration.days,
        name: duration.name,
      });
  }, []);

  const validate = () => {
    let formErrors = {};
    if (!formData.days) formErrors.days = "Duration days is required";
    if (!formData.name) formErrors.name = "Duration name is required";
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);

      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(`${apiUrl}/durations`, formData, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          });

          setDurations((prev) => [response.data, ...prev]);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>Duration: ${response.data?.name} added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            name: "",
            days: "",
          });
        } catch (error) {
          console.error("Error submitting form:", error.response.data);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response?.data?.error
                ? error.response.data.error
                : error.response.data,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/durations/${duration.id}`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Duration has been updated successfully</b>",
          });

          setDurations((prev) =>
            prev.map(
              (c) =>
                c.id === duration.id // Check if the duration matches
                  ? { ...duration, days: formData.days, name: formData.name } // Update the duration with new values
                  : duration // Leave the other durations unchanged
            )
          );

          closeModal();
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {duration ? "Updating Duration Information" : "Duration Form"}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="name">
            Duration name <span className="text-red-500">*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.name ? "border-red-500" : "border-black"
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-xs mt-1">{errors.name}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="days">
            Days <span className="text-red-500">*</span>
          </label>
          <input
            id="days"
            name="days"
            type="number"
            value={formData.days}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.days ? "border-red-500" : "border-black"
            }`}
          />
          {errors.days && (
            <p className="text-red-500 text-xs mt-1">{errors.days}</p>
          )}
        </div>

        <div className="md:col-span-2 col-span-1 flex gap-2 justify-evenly">
          <button
            type="submit"
            disabled={loading}
            className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
          >
            {duration ? (
              <div> {loading ? "Processing ..." : "Update"}</div>
            ) : (
              <div> {loading ? "Processing ..." : "Submit"}</div>
            )}
          </button>

          <div
            type="button"
            disabled={loading}
            onClick={closeModal}
            className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
          >
            Cancel
          </div>
        </div>
      </div>
    </form>
  );
};

export default DurationAdd;

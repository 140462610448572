import React, { useEffect, useState } from "react";
import { Home, Login, Welcome } from "./containers";
import axios from "axios";
import { apiUrl } from "./utils/links";
import { AuthContext } from "./context/Contexts";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState({
    firstname: "",
    lastname: "",
    role: "",
    username: "",
    email: "",
    phone: "",
    image: "",
    loginStatus: false,
    isActive: false,
    ait: null,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiUrl}/auth/authenticatedUser`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      })
      .then((res) => {
        setLoading(false);
        setAuthUser((authUser) => ({
          ...authUser,
          firstname: res.data.user.firstname,
          lastname: res.data.user.lastname,
          role: res.data.user.role,
          username: res.data.user.username,
          email: res.data.user.email,
          phone: res.data.user.phone,
          image: res.data.user.image,
          loginStatus: res.data.login_status,
          isActive: res.data.user.isActive,
          ait: res.data.iat,
        }));
      })
      .catch((err) => {
        setLoading(false);
        console.log("verification_error", err);
      });
  }, []);

  if (!loading) {
    return (
      <AuthContext.Provider value={{ authUser, setAuthUser }}>
        <div className="md:mb-0px] mb-[70px]">
          <ToastContainer />
          <Routes>
            <Route path="/login" element={<Login />} exact />
            <Route path="/landing" element={<Welcome />} exact />
            <Route path="/*" element={<Home />} exact />
          </Routes>
        </div>
      </AuthContext.Provider>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-full h-[50vh]">
        <img src="https://loading.io/assets/mod/spinner/spinner/lg.gif" alt="Loading ..." srcset="" />
      </div>
    );
  }
}

export default App;

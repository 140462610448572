import React, { useEffect, useState } from "react";
import { BiData } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import { FinReport, Loading } from "../components";

const FinancialReport = () => {
  const today = new Date().toISOString().split("T")[0];

  const [dates, setDates] = useState({
    startDate: today,
    endDate: today,
  });
  const [errors, setErrors] = useState({});

  const headers = {
    loginToken: localStorage.getItem("loginToken"),
  };

  const receiptsApi = useApi();
  const incomeApi = useApi();
  const paymentsApi = useApi();
  const gvtApi = useApi();

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDates((prevDates) => {
      const updatedDates = { ...prevDates, [name]: value };

      // Automatically update endDate when startDate is changed
      if (name === "startDate") {
        updatedDates.endDate = value;
      }

      return updatedDates;
    });
  };

  const validate = () => {
    let formErrors = {};

    if (!dates.startDate) {
      formErrors.startDate = "Start date is required";
    }

    if (!dates.endDate) {
      formErrors.endDate = "End date is required";
    }

    if (
      dates.startDate &&
      dates.endDate &&
      new Date(dates.startDate) > new Date(dates.endDate)
    ) {
      formErrors.endDate =
        "End date should be greater than or equal to start date";
    }
    return formErrors;
  };

  const handleLoadData = async () => {
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setErrors({});
      await receiptsApi.refetch(
        `${apiUrl}/receipts/${dates.startDate}/${dates.endDate}`,
        "GET",
        null,
        { headers }
      );

      await incomeApi.refetch(
        `${apiUrl}/income/${dates.startDate}/${dates.endDate}`,
        "GET",
        null,
        { headers }
      );

      await paymentsApi.refetch(
        `${apiUrl}/payments/${dates.startDate}/${dates.endDate}`,
        "GET",
        null,
        { headers }
      );

      await gvtApi.refetch(
        `${apiUrl}/gvt/${dates.startDate}/${dates.endDate}`,
        "GET",
        null,
        { headers }
      );
    }
  };

  const loading =
    receiptsApi.loading ||
    incomeApi.loading ||
    paymentsApi.loading ||
    gvtApi.loading;
  const error =
    receiptsApi.error || incomeApi.error || paymentsApi.error || gvtApi.error;

  return (
    <div className="w-full flex h-full flex-col items-center relative gap-3">
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Financial Report
      </div>
      <div className="text-lg font-bold mt-[20px]">Monitor Your Cash Flow</div>
      <div className="w-full flex lg:flex-row items-center flex-col gap-4 justify-start md:gap-[15px] border-b-2 border-b-gray-300 rounded-lg bg-gray-100 p-2">
        <div className="relative flex flex-col items-center">
          <label htmlFor="startDate">Start date</label>
          <input
            type="date"
            name="startDate"
            value={dates.startDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.startDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select start date"
          />
          {errors.startDate && (
            <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
          )}
        </div>
        <div className="relative flex flex-col items-center">
          <label htmlFor="endDate">End date</label>
          <input
            type="date"
            name="endDate"
            value={dates.endDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.endDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select end date"
          />
          {errors.endDate && (
            <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>
          )}
        </div>
        <div
          onClick={handleLoadData}
          className="cursor-pointer ml-2 lg:self-end p-[7px] gap-1 shadow-xl bg-[var(--primary-dark)] px-4 rounded-md text-white font-semibold flex flex-row items-center"
        >
          <BiData size={20} />
          Get data/Refresh
        </div>
      </div>
      <div className="flex flex-wrap items-center flex-col w-full">
        <div className="w-full">
          {loading ? (
            <Loading />
          ) : error ? (
            <div className="w-full text-center text-lg text-red-600 mt-[40px]">
              Oops! {error}
            </div>
          ) : receiptsApi.data &&
            incomeApi.data &&
            paymentsApi.data &&
            gvtApi.data ? (
            <FinReport
              receipts={receiptsApi.data}
              income={incomeApi.data}
              payments={paymentsApi.data}
              gvtFees={gvtApi.data}
              dates={dates}
            />
          ) : (
            <div className="w-full text-center mt-[40px]">
              <p className="text-lg">Nothing to display here.</p>
              <p className="mt-[15px] italic">
                Please load the data by selecting the date range above
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="my-[50px]">
        <hr />
      </div>
    </div>
  );
};

export default FinancialReport;

import React from "react";
import { CgClose } from "react-icons/cg";
import Modal from "react-modal";

Modal.setAppElement("#root");

const GlobalModal = ({ modalIsOpen, closeModal, modalContent }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Pamseu Modal"
      shouldCloseOnOverlayClick={false} // Prevents closing when clicking outside the modal
      shouldCloseOnEsc={false} // Prevents closing when pressing the Escape key
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          color: "gray",
          marginTop: "50px",
        },
      }}
    >
      <div className="relative">
        <div
          className="absolute top-[-40px] right-0 md:m-2 m-1 bg-red-500 rounded-full p-1 cursor-pointer animate-pulse"
          onClick={closeModal}
        >
          <CgClose className="text-white font-bold" size={26} />
        </div>

        <div className="mt-6">
        {modalContent}
        </div>
        
      </div>
    </Modal>
  );
};

export default GlobalModal;

export default function generateTimeRanges(startTime, endTime, interval) {
  const timeRanges = [];
  let [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  while (
    startHour < endHour ||
    (startHour === endHour && startMinute < endMinute)
  ) {
    const endMinuteAdjusted = startMinute + interval;
    const endHourAdjusted = startHour + Math.floor(endMinuteAdjusted / 60);
    const rangeEndMinute = endMinuteAdjusted % 60;

    // Create time range string
    const rangeString = `${String(startHour).padStart(2, "0")}:${String(
      startMinute
    ).padStart(2, "0")}-${String(endHourAdjusted).padStart(2, "0")}:${String(
      rangeEndMinute
    ).padStart(2, "0")}`;

    // Add object to array
    timeRanges.push({ label: rangeString, value: rangeString });

    // Update start time
    startMinute += interval;
    if (startMinute >= 60) {
      startMinute -= 60;
      startHour += 1;
    }
  }

  return timeRanges;
}

import { useState } from "react";
import axios from "axios";

const useApi = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (
    url,
    method = "GET",
    body = null,
    options = {},
    callback
  ) => {
    setLoading(true);
    try {
      const response = await axios({
        url,
        method,
        data: body,
        ...options,
      });
      setData(response.data);
      setError(null);
      if (callback) callback(null, response.data, error);
    } catch (err) {
      setError(err.response ? err.response.data.error : err.message);
      setData(null);
      if (callback) callback(err, null);
    } finally {
      setLoading(false);
    }
  };

  return { data, setData, loading, error, refetch: fetchData };
};

export default useApi;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import axios from "axios";
import useApi from "../../hooks/useApi";
import { Loading } from "..";
import { formattedAmount } from "../../utils/formattedAmount";

const Form = ({ item, closeModal, setDurations, data }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    courseID: "",
    duration: "",
    fees: "0.0",
    assesmentFee: "0.0",
  });

  const headers = {
    loginToken: localStorage.getItem("loginToken"),
  };

  const {
    data: courses,
    loading: isCourseLoading,
    error: courseError,
    refetch: getCourses,
  } = useApi();

  const {
    data: durations,
    loading: isDurationLoading,
    error: durationError,
    refetch: getDurations,
  } = useApi();

  useEffect(() => {
    const loadCourses = async () => {
      await getCourses(`${apiUrl}/courses`, "GET", null, { headers });
    };
    const loadDurations = async () => {
      await getDurations(`${apiUrl}/durations`, "GET", null, { headers });
    };

    loadCourses();
    loadDurations();

    if (item) {
      setFormData({
        courseID: item?.courseID,
        duration: item?.duration,
        fees: item?.fees.toString(),
        assesmentFee: item?.assesmentFee.toString(),
      });
    }
  }, []);

  const validate = () => {
    let formErrors = {};
    if (!formData.courseID) formErrors.courseID = "Course is required";
    if (!formData.duration) formErrors.duration = "Duration is required";
    if (formData.fees) {
      if (isNaN(formData.fees) || formData.fees < 0) {
        formErrors.fees =
          "Fees amount must be a valid number and not less than 0";
      }
    } else {
      formErrors.fees = "Fees amount is required";
    }
    if (isNaN(formData.assesmentFee) || formData.assesmentFee < 0) {
      formErrors.assesmentFee =
        "Assessment amount must be a valid number and not less than 0";
    }
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "fees") {
      // Remove any commas from the value
      updatedValue = value.replace(/,/g, "");
    }

    if (name === "assesmentFee") {
      // Remove any commas from the value
      updatedValue = value.replace(/,/g, "");
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedCourse = courses.find(
      (option) => option.id.toString() === formData.courseID
    );
    const selectedDuration = durations.find(
      (option) => option.id.toString() === formData.duration
    );

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);

      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(
            `${apiUrl}/courseDurations`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          setDurations((prev) => [
            {
              ...response?.data,
              Course: selectedCourse,
              Duration: selectedDuration,
            },
            ...prev,
          ]);
          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>Fees added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            courseID: "",
            duration: "",
            fees: "0.0",
            assesmentFee: "0.0",
          });
        } catch (error) {
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response?.data?.error
                ? error.response.data.error
                : error.response.data,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/courseDurations/${item.courseID}/${item.duration}`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Fees has been updated successfully</b>",
          });

          console.log(data);

          setDurations((prev) =>
            prev.map(
              (c) =>
                c.courseID.toString() === formData.courseID.toString() &&
                c.duration.toString() === formData.duration.toString()
                  ? {
                      ...c,
                      fees: formData.fees,
                      assesmentFee: formData.assesmentFee,
                    } // Update the item with new values
                  : c // Leave the other items unchanged
            )
          );

          closeModal();
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  if (isCourseLoading || isDurationLoading) return <Loading />;

  if (courseError) {
    return (
      <div className="text-red-600 w-full m-auto text-center">
        Error loading Courses : <br /> {JSON.stringify(courseError)}
      </div>
    );
  } else if (durationError) {
    return (
      <div className="text-red-600 w-full m-auto text-center">
        Error loading Durations : <br /> {JSON.stringify(durationError)}
      </div>
    );
  }

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {item ? "Updating Fees" : "Fees Form"}
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="courseID">
            Course <span className="text-red-500">*</span>
          </label>
          <select
            name="courseID"
            type="text"
            disabled={item}
            value={formData.courseID}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.courseID ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Course</option>
            {courses?.length > 0 ? (
              courses.map((course, index) => (
                <option value={course.id}>{course.code}</option>
              ))
            ) : (
              <option value=""> ---- No courses found -----</option>
            )}
          </select>
          {errors.courseID && (
            <p className="text-red-500 text-xs mt-1">{errors.courseID}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="duration">
            Duration <span className="text-red-500">*</span>
          </label>
          <select
            name="duration"
            type="text"
            disabled={item}
            value={formData.duration}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.duration ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Duration</option>
            {durations?.length > 0 ? (
              durations.map((duration, index) => (
                <option value={duration.id}>{duration?.name}</option>
              ))
            ) : (
              <option value=""> ---- No Durations found -----</option>
            )}
          </select>
          {errors.duration && (
            <p className="text-red-500 text-xs mt-1">{errors.duration}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="fees">
            Fees Amount <span className="text-red-500">*</span>
          </label>
          <input
            id="fees"
            name="fees"
            type="text"
            value={formattedAmount(formData.fees)}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.fees ? "border-red-500" : "border-black"
            }`}
          />
          {errors.fees && (
            <p className="text-red-500 text-xs mt-1">{errors.fees}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="assesmentFee"
          >
            Assessment Fees <span className="text-red-500"></span>
          </label>
          <input
            id="assesmentFee"
            name="assesmentFee"
            type="text"
            value={formattedAmount(formData.assesmentFee)}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.assesmentFee ? "border-red-500" : "border-black"
            }`}
          />
          {errors.assesmentFee && (
            <p className="text-red-500 text-xs mt-1">{errors.assesmentFee}</p>
          )}
        </div>

        <div className="lg:col-span-4 md:col-span-3  col-span-1 flex gap-2 justify-evenly">
          <button
            type="submit"
            disabled={loading}
            className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
          >
            {item ? (
              <div> {loading ? "Processing ..." : "Update"}</div>
            ) : (
              <div> {loading ? "Processing ..." : "Submit"}</div>
            )}
          </button>

          <div
            type="button"
            disabled={loading}
            onClick={closeModal}
            className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
          >
            Cancel
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;

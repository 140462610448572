import React from "react";

const Loading = () => {
  return (
    <div className="max-w-[990px] mx-auto p-4 flex items-center justify-center py-6">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
        alt="Loading ..."
        className="w-[60px]"
      />
    </div>
  );
};

export default Loading;

import React from "react";

const NotFound = () => {
  return (
    <div className="w-full h-[50vh] flex items-center justify-center">
      The page you are looking for does not exist
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import axios from "axios";
import useApi from "../../hooks/useApi";
import Loading from "../Loading";

const Form = ({ data, closeModal, setData }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    regNo: "",
    insurance_date: "",
    cof_date: "",
    instructor: "",
  });

  const staffApi = useApi();

  useEffect(() => {
    const loadStaff = async () => {
      await staffApi.refetch(`${apiUrl}/staff`, "GET", null, {
        headers: { loginToken: localStorage.getItem("loginToken") },
      });
    };

    loadStaff();
  }, []);

  useEffect(() => {
    data &&
      setFormData({
        id: data.id,
        regNo: data.regNo,
        insurance_date: data.insurance_date,
        cof_date: data.cof_date,
        instructor: data.instructor,
      });
  }, []);

  const validate = () => {
    let formErrors = {};
    if (!formData.regNo) formErrors.regNo = "Registration number is required";
    if (!formData.insurance_date)
      formErrors.insurance_date = "Insurance expiry date is required";
    if (!formData.cof_date) formErrors.cof_date = "COF expiry date is required";
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);

      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(`${apiUrl}/vehicles`, formData, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          });

          setData((prev) => [{ ...response.data }, ...prev]);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>Vehicle: ${response.data?.regNo} added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            regNo: "",
            insurance_date: "",
            cof_date: "",
            instructor: "",
          });
        } catch (error) {
          console.error("Error submitting form:", error.response.data);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response?.data?.error
                ? error.response.data.error
                : error.response.data,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/vehicles/${data.id}`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Vehicle has been updated successfully</b>",
          });

          setData((prev) =>
            prev.map((c) =>
              c.id === data.id // Check if the course matches
                ? {
                    ...c,
                    regNo: formData.regNo,
                    cof_date: formData.cof_date,
                    insurance_date: formData.insurance_date,
                    instructor: formData.instructor,
                  }
                : c
            )
          );

          closeModal();
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {data ? "Updating Vehicle Information" : "Vehicle Form"}
      </h2>

      {loading && <Loading />}
      {staffApi.loading && <Loading />}
      {staffApi.error && (
        <div className="text-red-600 w-full text-center p-3">
          {staffApi.error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="code">
            Registration No. <span className="text-red-500">*</span>
          </label>
          <input
            id="regNo"
            name="regNo"
            type="text"
            value={formData.regNo}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.regNo ? "border-red-500" : "border-black"
            }`}
          />
          {errors.regNo && (
            <p className="text-red-500 text-xs mt-1">{errors.regNo}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="insurance_date"
          >
            Insurance Expiry Date <span className="text-red-500">*</span>
          </label>
          <input
            id="insurance_date"
            name="insurance_date"
            type="date"
            value={formData.insurance_date}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.insurance_date ? "border-red-500" : "border-black"
            }`}
          />
          {errors.insurance_date && (
            <p className="text-red-500 text-xs mt-1">{errors.insurance_date}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="cof_date">
            COF Expiry Date <span className="text-red-500">*</span>
          </label>
          <input
            id="cof_date"
            name="cof_date"
            type="date"
            value={formData.cof_date}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.cof_date ? "border-red-500" : "border-black"
            }`}
          />
          {errors.cof_date && (
            <p className="text-red-500 text-xs mt-1">{errors.cof_date}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="instructor">
            Instructor
          </label>
          <select
            id="instructor"
            name="instructor"
            type="date"
            value={formData.instructor}
            onChange={handleChange}
            className={`w-full p-2 border rounded border-black`}
          >
            <option value="">Select instructor</option>
            {staffApi.data
              ?.filter((item) => item.department === "Driver")
              .map((item, index) => (
                <option key={index} value={item.fullname}>
                  {item.fullname}
                </option>
              ))}
          </select>
        </div>

        <div className="md:col-span-2 col-span-1 flex gap-2 justify-evenly">
          <button
            type="submit"
            disabled={loading}
            className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
          >
            {data ? (
              <div> {loading ? "Processing ..." : "Update"}</div>
            ) : (
              <div> {loading ? "Processing ..." : "Submit"}</div>
            )}
          </button>

          <div
            type="button"
            disabled={loading}
            onClick={closeModal}
            className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
          >
            Cancel
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { formattedAmount } from "../../utils/formattedAmount";
import { Loading, StudentBasicInfo } from "..";

const FeesAdd = ({ student, closeModal, receipt }) => {
  const [formData, setFormData] = useState({
    studentID: "",
    amount: "",
    modeOfPayment: "",
    paymentOf: "",
    reference: "",
    dateOfPayment: "",
    chequeNo: "",
    receiptNo: "",
  });

  const [governmentFees, setGovernmentFees] = useState([]);

  useEffect(() => {
    if (receipt) {
      setFormData({
        studentID: receipt.studentID,
        amount: receipt.amount,
        modeOfPayment: receipt.modeOfPayment,
        paymentOf: receipt.paymentOf.split(", "),
        reference: receipt.reference,
        dateOfPayment: receipt.dateOfPayment,
        chequeNo: receipt.chequeNo,
        receiptNo: receipt.receiptNo,
      });
    }
  }, []);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tempStudent, setTempStudent] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setFormData((prev) => ({ ...prev, studentID: student.studentID }));
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchFullInfo(student.studentID);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchGovernmentFees();
  }, []);

  const fetchFullInfo = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/students/${id}`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });
      if (response.data) {
        setTempStudent(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchGovernmentFees = async () => {
    try {
      const response = await axios.get(`${apiUrl}/govtFees`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });
      if (response.data) {
        setGovernmentFees(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, options } = e.target;

    if (name === "paymentOf") {
      // Get the selected options and extract their amounts
      const selectedOptions = [...options].filter((option) => option.selected);
      const selectedFees = selectedOptions.map((option) =>
        option.value.split("~")
      );

      // Extract and sum the amounts from the selected fees
      const totalAmount = selectedFees.reduce(
        (acc, fee) => acc + parseFloat(fee[1]),
        0
      );

      // Update the form data with the selected fees and the total amount
      setFormData({
        ...formData,
        paymentOf: selectedOptions.map((option) => option.value), // store selected values
        amount: totalAmount, // update the amount field
      });
    } else {
      let updatedValue = value;
      if (name === "amount") {
        // Remove commas if the amount is manually changed
        updatedValue = value.replace(/,/g, "");
      }

      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    }
  };

  const validate = () => {
    let formErrors = {};

    // Check if dateOfPayment is provided
    if (!formData.dateOfPayment) {
      formErrors.dateOfPayment = "Date of payment is required";
    } else {
      // Parse the input date and remove time component
      const inputDate = new Date(formData.dateOfPayment);
      inputDate.setHours(0, 0, 0, 0); // Normalize the time to 00:00:00

      // Get today's date and remove the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

      // Check if the input date is in the future (but allow today)
      if (inputDate > today) {
        formErrors.dateOfPayment = "Date of payment cannot be in the future";
      }
    }

    if (!formData.modeOfPayment)
      formErrors.modeOfPayment = "Mode of payment is required";
    if (!formData.paymentOf) formErrors.paymentOf = "Payment For: is required";
    else if (formData.paymentOf.length < 1)
      formErrors.paymentOf = "Payment For: is required";
    if (!formData.receiptNo) formErrors.receiptNo = "Receipt No. is required";
    if (formData.amount) {
      if (isNaN(formData.amount) || formData.amount <= 0) {
        formErrors.amount =
          "Fees amount must be a valid number and greater than 0";
      }
    } else {
      formErrors.amount = "Amount is required";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);
      const paymentNames = formData.paymentOf.map((val) => val.split("~")[0]);
      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(
            `${apiUrl}/gvt`,
            { ...formData, paymentOf: paymentNames.join(", ") },
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          fetchFullInfo(student.studentID);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>Receipt: ${response.data?.receiptNo} added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            studentID: student.studentID,
            amount: "",
            modeOfPayment: "",
            paymentOf: "",
            reference: "",
            dateOfPayment: "",
            chequeNo: "",
            receiptNo: "",
          });
        } catch (error) {
          console.error("Error submitting form:", error.response.data);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response?.data?.error
                ? error.response.data.error
                : error.response.data,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/gvt/${receipt.id}`,
            { ...formData, paymentOf: paymentNames.join(", ") },
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Receipt has been updated successfully</b>",
          });

          fetchFullInfo(student.studentID);

          setFormData({
            studentID: "",
            amount: "",
            modeOfPayment: "",
            paymentOf: "Fees",
            reference: "",
            dateOfPayment: "",
            chequeNo: "",
            receiptNo: "",
          });
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {receipt
          ? "Updating Traffic fees receipt"
          : "Student Traffic Fees Payment Form"}
      </h2>

      {loading ? (
        <Loading />
      ) : (
        <StudentBasicInfo
          student={student}
          tempStudent={tempStudent}
          caller={"RT"}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="receiptNo">
            Receipt Number <span className="text-red-500">*</span>
          </label>
          <input
            id="receiptNo"
            name="receiptNo"
            type="text"
            value={formData.receiptNo}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.receiptNo ? "border-red-500" : "border-black"
            }`}
          />
          {errors.receiptNo && (
            <p className="text-red-500 text-xs mt-1">{errors.receiptNo}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="dateOfPayment"
          >
            Date of Payment <span className="text-red-500">*</span>
          </label>
          <input
            id="dateOfPayment"
            name="dateOfPayment"
            type="date"
            value={formData.dateOfPayment}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dateOfPayment ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dateOfPayment && (
            <p className="text-red-500 text-xs mt-1">{errors.dateOfPayment}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="modeOfPayment"
          >
            Mode of Payment <span className="text-red-500">*</span>
          </label>
          <select
            id="modeOfPayment"
            name="modeOfPayment"
            value={formData.modeOfPayment}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.modeOfPayment ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Mode of Payment</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="From other Banks">From other Banks</option>
            <option value="Mo626">Mo626</option>
            <option value="Direct Banking">Direct Banking</option>
          </select>
          {errors.modeOfPayment && (
            <p className="text-red-500 text-xs mt-1">{errors.modeOfPayment}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="reference">
            Reference
          </label>
          <input
            id="reference"
            name="reference"
            type="text"
            value={formData.reference}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.reference ? "border-red-500" : "border-black"
            }`}
          />
          {errors.reference && (
            <p className="text-red-500 text-xs mt-1">{errors.reference}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="chequeNo">
            Cheque No.
          </label>
          <input
            id="chequeNo"
            name="chequeNo"
            type="text"
            value={formData.chequeNo}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.chequeNo ? "border-red-500" : "border-black"
            }`}
          />
          {errors.reference && (
            <p className="text-red-500 text-xs mt-1">{errors.chequeNo}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="paymentOf">
            Payment For <span className="text-red-500">*</span>
          </label>
          <select
            id="paymentOf"
            name="paymentOf"
            value={formData.paymentOf}
            onChange={handleChange}
            multiple
            className={`w-full p-2 border rounded ${
              errors.paymentOf ? "border-red-500" : "border-black"
            }`}
          >
            {governmentFees.map((fee, index) => (
              <option key={index} value={fee.name + "~" + fee.amount}>
                {fee.name}
              </option>
            ))}
          </select>
          {errors.modeOfPayment && (
            <p className="text-red-500 text-xs mt-1">{errors.paymentOf}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="amount">
            Amount received <span className="text-red-500">*</span>
          </label>
          <input
            id="amount"
            disabled
            name="amount"
            type="text"
            value={formattedAmount(formData.amount.toString())}
            onChange={handleChange}
            className={`w-full p-2 border cursor-not-allowed rounded ${
              errors.amount ? "border-red-500" : "border-black"
            }`}
          />
          {errors.amount && (
            <p className="text-red-500 text-xs mt-1">{errors.amount}</p>
          )}
        </div>
      </div>

      <div className=" flex gap-2 justify-evenly">
        <button
          type="submit"
          disabled={loading}
          className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
        >
          {receipt ? (
            <div> {loading ? "Processing ..." : "Update"}</div>
          ) : (
            <div> {loading ? "Processing ..." : "Submit"}</div>
          )}
        </button>

        <div
          type="button"
          disabled={loading}
          onClick={closeModal}
          className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default FeesAdd;

import React from "react";
import Select from "react-select";

const Form = ({ initiate, loading, vehicles, handleVehicleChange }) => {
  return (
    <div className="md:min-w-[25%] w-full flex flex-col h-full">
      <div className=" bg-slate-300 px-5 rounded-lg py-[3px] flex justify-between flex-wrap items-center">
        <h4 className="p-1 font-bold text-lg">TimeTabling</h4>
        <p>~on~ {new Date().toDateString()}</p>
      </div>
      <div className="flex-1 p-1 h-full flex flex-col gap-2 items-start mt-4">
        <div className="w-full">
          <label htmlFor="" className="ml-1 font-bold">
            Select Vehicle
          </label>
          <Select
            options={vehicles}
            onChange={handleVehicleChange}
            isSearchable
            placeholder="Search..."
          />
        </div>
        <button
          disabled={loading}
          onClick={initiate}
          className="self-center animate-pulse"
        >
          Initiate
        </button>
      </div>
      {/* <div className="self-end flex flex-col w-full gap-4 text-[10pt] p-2">
        <div className="w-full">
          <label htmlFor="" className="ml-1">
            Student
          </label>
          <Select
            options={students}
            onChange={handleChange}
            isSearchable
            placeholder="Search student..."
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className="ml-1">
            Pending student
          </label>
          <Select
            options={students}
            onChange={handleChange}
            isSearchable
            placeholder="Search student..."
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className="ml-1">
            Times
          </label>
          <Select
            options={timeRanges}
            onChange={handleChange}
            isSearchable
            placeholder="Search student..."
          />
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="" className="ml-1">
            Lessons
          </label>
          <input
            type="number"
            onChange={handleChange}
            className="border-[2px] p-2 rounded-sm  "
            placeholder=""
          />
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="" className="ml-1">
            Bal
          </label>
          <input
            type="number"
            onChange={handleChange}
            className="border-[2px] p-2 rounded-sm"
            placeholder=""
          />
        </div>
      </div>
      <div className="justify-between flex flex-wrap mt-4 pr-1 text-[11pt] pb-2">
        <div className="px-4 py-1 rounded-md bg-green-600 text-white shadow-lg hover:text-[12pt] cursor-pointer">
          Save
        </div>
        <div className="px-4 py-1 rounded-md bg-blue-600 text-white shadow-lg hover:text-[12pt] cursor-pointer">
          Update
        </div>
        <div className="px-4 py-1 rounded-md bg-red-600 text-white shadow-lg hover:text-[12pt] cursor-pointer">
          Delete
        </div>
      </div> */}
    </div>
  );
};

export default Form;

import React, { useState } from "react";
import { BiEdit, BiSearch, BiTrash } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import { Loading, Modal, VehicleForm } from "..";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../utils/links";

const Cards = ({ data, setData }) => {
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCreate = (data) => {
    setModalContent(<VehicleForm closeModal={closeModal} setData={setData} />);
    openModal();
  };

  const handleUpdate = (data) => {
    setModalContent(
      <VehicleForm data={data} closeModal={closeModal} setData={setData} />
    );
    openModal();
  };

  // Search handler for input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    const id = item?.id ? item?.id.toString().toLowerCase() : "";
    const regNo = item?.regNo ? item?.regNo.toString().toLowerCase() : "";
    const instructor = item?.instructor ? item.instructor.toLowerCase() : "";

    return (
      id.includes(searchTerm.toLowerCase()) ||
      regNo.includes(searchTerm.toLowerCase()) ||
      instructor.includes(searchTerm.toLowerCase())
    );
  });
  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${item?.regNo}?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .delete(`${apiUrl}/vehicles/${item.id}`, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          })
          .then((res) => {
            setData((prev) => prev.filter((c) => c.id !== item.id));
            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Vehicle record has been successfully deleted.",
              icon: "success",
              timer: 2000,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
              showConfirmButton: false, // Automatically close after 2 seconds
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              Swal.fire({
                title: "Error!",
                text: err.response.data.error,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            }
          });
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-4 mb-[40px]">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="flex w-full md:justify-between md:flex-row flex-col-reverse gap-2 items-center">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ..."
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>

        <div className="text-gray-400 font-semibold">
          (~{filteredData.length}~) Records
        </div>

        <div
          className="px-4 py-2  mr-5  animate-bounce rounded-lg bg-gray-500 text-white flex items-center gap-1 cursor-pointer"
          onClick={handleCreate}
        >
          <CgAdd size={20} />
          Add vehicle
        </div>
      </div>

      {loading && <Loading />}
      {filteredData.length > 0 ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
          {filteredData.map((item) => (
            <div
              key={item.id}
              className="p-3 border-2 shadow-lg rounded-xl flex flex-col justify-between items-center gap-3 relative"
            >
              <div className="absolute top-0 left-0 m-2">
                <p className="text-sm text-gray-300">ID: {item?.id}</p>
              </div>
              <h1 className="text-xl mt-2 font-semibold border-b-[2px] border-gray-300 p-2 w-full text-center">
                {item?.regNo}
              </h1>
              <p className="text-sm">Ins Expiry: {item.insurance_date}</p>
              <p className="text-sm">COF Expiry: {item.cof_date}</p>
              <p className="text-xs text-center font-semibold text-wrap">
                Instructor: {item?.instructor}
              </p>

              <div className="flex justify-center gap-[35px] border-t-[1px] border-red-200 w-full items-center p-1 mt-2">
                <BiEdit
                  size={20}
                  title="Update"
                  className="cursor-pointer"
                  onClick={() => handleUpdate(item)}
                />
                <BiTrash
                  size={20}
                  color="red"
                  title="Delete"
                  className="cursor-pointer"
                  onClick={() => handleDelete(item)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="m-auto max-w-full text-xl mt-5 font-semibold uppercase">
          No Vehicles found !
        </div>
      )}
    </div>
  );
};

export default Cards;

import React, { useEffect, useState } from "react";
import { BiEdit, BiSearch, BiTrash } from "react-icons/bi";
import { ExpenseForm, Loading, Modal } from "..";
import { toKwacha } from "../../utils/toKwacha";
import Swal from "sweetalert2";
import useApi from "../../hooks/useApi";
import { apiUrl } from "../../utils/links";

const Paginated = ({ data, setData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState();
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { loading, error, refetch } = useApi();

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    const id = item?.id ? item?.id.toString().toLowerCase() : "";
    const name = item?.name ? item.name.toLowerCase() : "";
    const date = item.date ? item.date.toString().toLowerCase() : "";
    const userFname = item?.User?.firstname
      ? item?.User?.firstname.toString().toLowerCase()
      : "";
    const userLname = item?.User?.lastname
      ? item?.User?.lastname.toString().toLowerCase()
      : "";

    return (
      id.includes(searchTerm.toLowerCase()) ||
      name.includes(searchTerm.toLowerCase()) ||
      date.includes(searchTerm.toLowerCase()) ||
      userFname.includes(searchTerm.toLowerCase()) ||
      userLname.includes(searchTerm.toLowerCase())
    );
  });

  // Update the count whenever currentRecords changes
  useEffect(() => {
    setCount(filteredData.length);
  }, [filteredData]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleUpdate = (item) => {
    setModalContent(
      <ExpenseForm closeModal={closeModal} item={item} setData={setData} />
    );
    openModal();
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete receipt  (${item.name})?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        refetch(`${apiUrl}/expenses/${item.id}`, "DELETE", null, {
          headers: {
            loginToken: localStorage.getItem("loginToken"),
          },
        });

        if (!loading) {
          setData(data.filter((d) => d.id !== item.id));
          Swal.fire({
            title: "Deleted!",
            text: `${item.name} has been successfully deleted.`,
            icon: "success",
            timer: 2000,
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            showConfirmButton: false, // Automatically close after 2 seconds
          });
        }

        if (error) {
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
          });
        }
      }
    });
  };

  return (
    <div className="h-full pl-[10px] w-full">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="text-gray-400 mx-4 my-1 font-bold">
        ~{`${count} Record(s)`}~
      </div>
      {/* Search and Records per Page */}
      <div className="flex items-center md:gap-1 w-full lg:justify-between gap-4 md:justify-evenly justify-evenly md:flex-row flex-col">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ... "
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>
        <div className="md:mr-5 mr-1 flex md:ml-1 ml-8 md:pr-4">
          <label className="text-sm font-semibold">
            Records per page:
            <input
              type="number"
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              min={1}
              className="ml-2 p-1 border border-black rounded max-w-[100px] pl-3"
            />
          </label>
        </div>
      </div>

      {loading && <Loading />}

      {/* Table */}
      <div className="overflow-x-auto mt-4 md:pr-2 pr-1">
        <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Date added</th>
              <th className="px-4 py-2">Added by</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((item, index) => (
              <tr key={index} className="text-center hover:bg-slate-200">
                <td className="px-4 py-2">{item?.id}</td>
                <td className="px-4 py-2">{item?.name}</td>
                <td className="px-4 py-2">
                  {item?.date && item.date.split("~")[0]}
                </td>
                <td className="px-4 py-2">
                  {item?.User?.firstname + " " + item?.User?.lastname}
                </td>
                <td className="px-4 py-2 relative text-left flex items-center gap-2">
                  <BiEdit
                    size={20}
                    title="Update"
                    className="cursor-pointer"
                    onClick={() => handleUpdate(item)}
                  />
                  <BiTrash
                    className="cursor-pointer text-red-600"
                    size={24}
                    onClick={() => handleDelete(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex flex-wrap gap-3 items-center mb-[20px] justify-center w-full mt-3 border-t-2 py-2">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          First
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          Last
        </button>
      </div>
    </div>
  );
};

export default Paginated;

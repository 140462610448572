import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import { readableTimestamp } from "./../../utils/readableTimeStamps";
import { QuickPassLogo } from "..";

const Report = ({ data, allocDate, closeModal }) => {
  const print = (id) => {
    const element = document.getElementById(id);
    html2pdf(element, {
      margin: 2, // Reduced margin for fitting more content
      filename: `${
        data[0].vehicle
      }-${allocDate} allocation_${readableTimestamp()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 }, // Higher scale for quality
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Portrait A4
    });
  };

  useEffect(() => {
    print("report-content");
    closeModal();
  }, []);

  return (
    <div className="container mx-auto p-6 gap-6 flex flex-col">
      {/* Tables Container */}
      <div className="w-full flex flex-col gap-[20px]" id="report-content">
        <div className="mb-8 flex md:justify-between justify-center items-center flex-wrap gap-3 w-full">
          <div className="md:grid grid-cols-5 gap-1 ">
            <div className="col-span-3 self-start flex mb-2">
              <QuickPassLogo />
            </div>

            <h2 className="text-xl font-bold text-center col-span-2">
              Allocation Report
            </h2>

            <p className="text-sm ">
              <span className="font-semibold">Allocation Date:.</span>{" "}
              {allocDate || "N/A"}
            </p>

            <p className="text-sm ml-5">
              <span className="font-semibold">Vehicle:.</span>{" "}
              {data[0]?.vehicle}
            </p>
            <p className="text-sm ">
              <span className="font-semibold">Instructor:.</span>{" "}
              {data[0]?.Vehicle?.instructor}
            </p>

            <p className="text-sm">
              <span className="font-semibold">Date printed: </span>
              {new Intl.DateTimeFormat("en-GB").format(new Date())}
            </p>
            <p className="text-sm">
              <span className="font-semibold">Total records:</span>{" "}
              {data.length}
            </p>
          </div>

          {/* Table */}
          <div className="overflow-x-auto mt-4 md:pr-2 pr-1 w-full">
            <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="px-4 py-2">Time</th>
                  <th className="px-4 py-2">Student</th>
                  <th className="px-4 py-2">Lessons</th>
                  <th className="px-4 py-2">Bal</th>
                 
                  <th className="px-4 py-2">Pending</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="text-center hover:bg-slate-200">
                    <td className="px-4 py-2">{item?.time}</td>
                    <td className="px-4 py-2">{item?.Student?.fullname}</td>
                    <td className="px-4 py-2">{item?.less}</td>
                    <td className="px-4 py-2">{item?.bal}</td>
                    
                    <td className="px-4 py-2">{item?.pending}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;

import React from "react";
import { toKwacha } from "../../utils/toKwacha";

const BasicInformation = ({ student, tempStudent, caller }) => {
  return (
    <div className="w-full p-2 bg-slate-100 mb-4">
      <h3 className="text-lg font-bold py-2">Student Information</h3>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3">
        <p className="flex flex-wrap gap-1">
          <span className="font-medium">Student ID: </span>{" "}
          {student?.studentID || "N/A"}
        </p>
        <p className="flex flex-wrap gap-1">
          <span className="font-medium">Student Name: </span>{" "}
          {student?.fullname || "N/A"}
        </p>
        <p className="flex flex-wrap gap-1">
          <span className="font-medium">Course: </span>{" "}
          {student?.course || "N/A"}
        </p>
        <p className="flex flex-wrap gap-1">
          <span className="font-medium">Duration: </span>{" "}
          {student?.duration || "N/A"}
        </p>
        <p className="flex flex-wrap gap-1">
          <span className="font-medium">Date Registered: </span>{" "}
          {student?.dateRegistered || "N/A"}
        </p>

        {caller === "RT" ? (
          <div className="lg:col-span-3 md:col-span-2 col-span-1 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 bg-slate-200 p-1 rounded-lg ">
            <p className="flex flex-wrap gap-1">
              <span className="font-medium">Total Government Fees:</span>{" "}
              {toKwacha(tempStudent?.totalGvtFees) || 0}
            </p>

            <p className="flex flex-wrap gap-1">
              <span className="font-medium">Total Paid:</span>{" "}
              {toKwacha(tempStudent?.totalPaidGvtFees) || 0}
            </p>

            <p className="flex flex-wrap gap-1">
              <span className="font-medium">Balance:</span>{" "}
              {toKwacha(tempStudent?.gvtBalance) || 0}
            </p>
          </div>
        ) : (
          caller !== "test" && (
            <div className="lg:col-span-3 md:col-span-2 col-span-1 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 bg-slate-200 p-1 rounded-lg ">
              <p className="flex flex-wrap gap-1">
                <span className="font-medium">Initial Fees:</span>{" "}
                {toKwacha(tempStudent?.initialFees) || 0}
              </p>

              <p className="flex flex-wrap gap-1">
                <span className="font-medium">Discount:</span>{" "}
                {toKwacha(tempStudent?.student?.Discount?.amount).includes(
                  "NaN"
                )
                  ? "N/A"
                  : toKwacha(tempStudent?.student?.Discount?.amount)}
              </p>

              <p className="flex flex-wrap gap-1">
                <span className="font-medium">Current Fees:</span>{" "}
                {toKwacha(tempStudent?.fees) || 0}
              </p>

              <p className="flex flex-wrap gap-1">
                <span className="font-medium">Total Paid:</span>{" "}
                {toKwacha(tempStudent?.sum) || 0}
              </p>

              <p
                className={
                  tempStudent?.balance === 0
                    ? "text-green-600 flex flex-wrap gap-1"
                    : `text-red-500 flex flex-wrap gap-1`
                }
              >
                <span className="font-medium"> Fees Balance:</span>{" "}
                {toKwacha(tempStudent?.balance) || 0}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BasicInformation;

import React from "react";
import logo from "./../assets/img/qpds logo.jpg";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link
      to={"/landing"}
      className="flex items-center gap-1 md:flex-row flex-col justify-center"
    >
      <img src={logo} alt="Quick pass driving School" className="w-[60px]" />
      <p className="font-bold text-md text-[var(--primary-dark)] md:self-end">
        Quickpass Driving School
      </p>
    </Link>
  );
};

export default Logo;

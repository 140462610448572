import React, { useEffect, useState } from "react";

const GroupedPayments = ({ data }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="container mx-auto pt-10 overflow-auto">
      <h2 className="md:text-xl text-lg font-bold mb-4 w-full p-2 bg-slate-400 text-center">
        Fees Collected per Month
      </h2>
      <div className="overflow-auto">
        <table className="table-auto border-collapse text-sm">
          <thead>
            <tr>
              <th className="px-4 py-2">Year</th>
              {months.map((month) => (
                <th key={month} className="px-2 py-2">
                  {month}
                </th>
              ))}
              <th className="px-4 py-2">Yearly Total (MK)</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((year) => (
              <tr key={year}>
                <td className="border px-4 py-2">{year}</td>
                {months.map((month) => (
                  <td key={month} className="border px-4 py-2 text-sm">
                    {data[year].months[month]
                      ? parseFloat(
                          data[year].months[month].total
                        ).toLocaleString()
                      : "0.00"}
                  </td>
                ))}
                <td className="border px-4 py-2 text-sm">
                  {Object.values(data[year].months)
                    .reduce((acc, { total }) => acc + parseFloat(total), 0)
                    .toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupedPayments;

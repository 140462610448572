export const timestampToDate = (stamp) => {
    // Check if the stamp is in seconds, convert to milliseconds if necessary
    const timestampInMilliseconds = stamp.toString().length === 10 ? stamp * 1000 : stamp;
  
    const date = new Date(timestampInMilliseconds);
  
    // Extract day, month, and year and format them as dd/mm/yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = date.toLocaleTimeString(); // "HH:MM:SS AM/PM" format
  
    return { date: formattedDate, time: formattedTime };
  };
  
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import {
  Loading,
  Modal,
  TrafficPaginated,
  TrafficFeesForm,
} from "../components";
import { CgAdd } from "react-icons/cg";

const Traffic = () => {
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, error, setData, loading, refetch } = useApi();

  useEffect(() => {
    const loadUsers = async () => {
      await refetch(`${apiUrl}/govtFees`, "GET", null, {
        headers: { loginToken: localStorage.getItem("loginToken") },
      });
    };

    loadUsers();
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCreateUser = () => {
    setModalContent(
      <TrafficFeesForm closeModal={closeModal} setData={setData} />
    );
    openModal();
  };

  return (
    <div className="w-full flex h-full flex-col items-center relative gap-3">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Traffic Fees
      </div>
      <div className="text-lg font-bold mt-[20px]">Manage Traffic Fees</div>
      <div className="w-full flex lg:flex-row  items-center flex-col gap-4 justify-start md:gap-[15px] mt-3 border-b-2 border-b-gray-300 rounded-lg bg-gray-100 p-2">
        <div
          onClick={handleCreateUser}
          className="flex h-[40px] flex-row gap-1 items-center py-2 px-4 rounded-md shadow-md cursor-pointer bg-gray-500 text-white animate-bounce"
        >
          <CgAdd size={20} />
          Add Fees
        </div>
      </div>
      <div className="flex flex-wrap items-center flex-col w-full">
        <div className="w-full">
          {loading ? (
            <Loading />
          ) : error ? (
            <div className="w-full text-center text-lg text-red-600 mt-[40px]">
              Oops! {error}
            </div>
          ) : data ? (
            <TrafficPaginated data={data} setData={setData} />
          ) : (
            <div className="w-full text-center mt-[40px] ">
              <p className="text-lg">Nothing to display here.</p>
              <p className="mt-[15px] italic">Data not available!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Traffic;

import React, { useEffect, useState } from "react";
import { Modal, StudentBasicInfo } from "..";
import useApi from "../../hooks/useApi";
import { apiUrl } from "../../utils/links";
import { Loading } from "./../../components";
import { toKwacha } from "../../utils/toKwacha";
import { BiPrinter } from "react-icons/bi";
import Report from "./Report";

const FeesInfo = ({ item, closeModal: modalClose }) => {
  const { data, loading, error, refetch } = useApi();
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const loadStudentFullInfo = async () => {
      await refetch(
        `${apiUrl}/students/${item?.Student?.studentID}`,
        "GET",
        null,
        {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }
      );
    };

    loadStudentFullInfo();
  }, []);

  const handleReport = () => {
    setModalContent(<Report data={item} closeModal={closeModal} />);
    openModal();
  };

  return (
    <div className="flex flex-col h-full">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      {loading && <Loading />}
      {item && item?.Student && (
        <div>
          <StudentBasicInfo student={item.Student} tempStudent={data} />
        </div>
      )}

      {error && (
        <div className=" m-4 w-full text-center text-lg text-red-500">
          {error}
        </div>
      )}

      {item && (
        <div className="p-2 mt-8 w-full">
          <div className="flex items-center justify-between pb-2 border-b-2 px-3 shadow-md">
            <h1 className="text-lg font-semibold w-full ">
              Transaction Information
            </h1>

            <div className="flex items-center gap-5">
              <BiPrinter
                className="cursor-pointer text-blue-800 animate-pulse"
                size={30}
                title="Print"
                onClick={handleReport}
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:p-3 p-2 gap-4">
            <div>
              <label htmlFor="">Receipt No.:</label>
              <p className={"text-gray-400"}>{item?.receiptNo}</p>
            </div>
            <div>
              <label htmlFor="">Date Paid.:</label>
              <p className={"text-gray-400"}>{item?.dateOfPayment}</p>
            </div>
            <div>
              <label htmlFor="">Amount.:</label>
              <p className={"text-gray-400"}>{toKwacha(item?.amount)}</p>
            </div>
            <div>
              <label htmlFor="">Payment For.:</label>
              <p className={"text-gray-400"}>{item?.paymentOf}</p>
            </div>
            <div>
              <label htmlFor="">Payment Mode.:</label>
              <p className={"text-gray-400"}>{item?.modeOfPayment}</p>
            </div>
            <div>
              <label htmlFor="">Reference.:</label>
              <p className={"text-gray-400"}>{item?.reference}</p>
            </div>
            <div>
              <label htmlFor="">Cheque No.:</label>
              <p className={"text-gray-400"}>{item?.chequeNo}</p>
            </div>
            <div>
              <label htmlFor="">Date Added.:</label>
              <p className={"text-gray-400"}>{item?.date}</p>
            </div>
            <div>
              <label htmlFor="">Added By.:</label>
              <p className={"text-gray-400"}>{item?.user}</p>
            </div>
            <div>
              <label htmlFor="">Signature.:</label>
              <p className={"text-gray-400"}>_______________</p>
            </div>
          </div>
        </div>
      )}

      <div
        onClick={modalClose}
        className="self-end font-bold w-[180px] mt-[30px] text-center flex items-center justify-center px-4 py-2 cursor-pointer bg-red-600 rounded-lg text-white"
      >
        Close
      </div>
    </div>
  );
};

export default FeesInfo;

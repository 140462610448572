import React, { useState, useEffect } from "react";
import Loading from "../Loading";
import Select from "react-select";
import { BiPrinter, BiSave, BiTrash } from "react-icons/bi";
import useApi from "../../hooks/useApi";
import { apiUrl } from "../../utils/links";
import Swal from "sweetalert2";

const Table = ({
  selectedVehicle,
  data,
  loading,
  error,
  students,
  tempStudents,
  handleAllocDate,
  allocDate,
  handlePrint,
  initiate,
}) => {
  const [formData, setFormData] = useState([]);
  const api = useApi();

  useEffect(() => {
    if (data) {
      // Initialize formData with each record
      setFormData(
        data.map((item) => ({
          ...item,
        }))
      );
    }
  }, [data]);

  // Handle input changes for all rows
  const handleInputChange = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };

  const handleSelectChange = (index, field, selectedOption) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = selectedOption ? selectedOption.value : "";
    setFormData(updatedFormData);
  };

  // Save all changes
  const handleSaveAll = async () => {
    try {
      const requests = formData.map((item) =>
        api.refetch(
          `${apiUrl}/allocation/${item.vehicle}/${item.time}`,
          "PUT",
          item,
          {
            headers: { loginToken: localStorage.getItem("loginToken") },
          }
        )
      );
      await Promise.all(requests); // Save all rows concurrently
      initiate();
      Swal.fire({
        title: "Saved!",
        text: "Saved successfully",
        icon: "success",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        timer: 1500,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } catch (err) {
      console.error("Error saving changes:", err);
      alert("Failed to save changes. Please try again.");
    }
  };

  const handleDelete = async (index) => {
    const updatedFormData = [...formData];

    await api.refetch(
      `${apiUrl}/allocation/${updatedFormData[index].vehicle}/${updatedFormData[index].time}`,
      "PUT",
      { student: 0, pending: null, bal: 0, less: 0 },
      {
        headers: { loginToken: localStorage.getItem("loginToken") },
      }
    );

    updatedFormData[index] = {
      ...updatedFormData[index],
      student: 0,
      pending: null,
      bal: 0,
      less: 0,
    };

    setFormData(updatedFormData);
  };

  const loadingTemp = loading || api.loading;
  const errorTemp = error || api.error;

  return (
    <div className="shadow-xl md:mt-0 mt-[30px] md:min-w-[72%] w-full md:overflow-auto container">
      <div className="px-5 rounded-lg py-[3px] w-full justify-evenly flex items-center p-2 md:text-md text-sm md:flex-nowrap flex-wrap gap-2">
        <div className="flex flex-col">
          <label htmlFor="allocation-date" className="text-sm font-semibold">
            Allocation Date
          </label>
          <input
            type="date"
            value={allocDate}
            onChange={handleAllocDate}
            id="allocation-date"
            className="py-1 px-4 min-w-[200px] border-[1px] border-gray-500 rounded-sm"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="instructor" className="text-sm font-semibold">
            Instructor
          </label>
          <p id="instructor" className="font-semibold">
            {selectedVehicle?.instructor + "~" + selectedVehicle?.regNo ||
              "N/A"}
          </p>
        </div>
        <div className="flex flex-wrap gap-2 animate-pulse">
          <BiSave
            size={30}
            onClick={handleSaveAll}
            title="Save all"
            className="cursor-pointer text-green-600"
          />
          <BiPrinter
            size={30}
            onClick={handlePrint}
            title="Print"
            className="cursor-pointer text-blue-600"
          />
        </div>
      </div>

      <hr className="my-3" />

      {loadingTemp ? (
        <Loading />
      ) : errorTemp ? (
        <div className="text-lg text-center w-full text-red-500 animate-pulse">
          {errorTemp}
        </div>
      ) : (
        <div className="p-4 overflow-auto">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Time
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Student
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Lessons
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Balance
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Pending
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.length > 0 ? (
                formData.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-200`}
                  >
                    <td className="border border-gray-300 px-4 py-2 text-sm">
                      {item.time || "N/A"}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm">
                      <Select
                        value={students.find(
                          (student) => student.value === item.student
                        )}
                        onChange={(selectedOption) =>
                          handleSelectChange(index, "student", selectedOption)
                        }
                        options={students}
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm">
                      <input
                        type="text"
                        value={item.less}
                        onChange={(e) =>
                          handleInputChange(index, "less", e.target.value)
                        }
                        className="px-4 py-[4px] border-[1px] border-gray-400 w-[60px]"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm">
                      <input
                        type="text"
                        value={item.bal}
                        onChange={(e) =>
                          handleInputChange(index, "bal", e.target.value)
                        }
                        className="px-4 py-[4px] border-[1px] border-gray-400 w-[60px]"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm">
                      <Select
                        value={tempStudents.find(
                          (student) => student.value === item.pending
                        )}
                        onChange={(selectedOption) =>
                          handleSelectChange(index, "pending", selectedOption)
                        }
                        options={tempStudents}
                      />
                    </td>
                    <td className="border px-4 py-2 text-xl flex flex-wrap gap-2 justify-center">
                      <BiTrash
                        color="red"
                        title="Clear"
                        className="cursor-pointer"
                        onClick={() => handleDelete(index)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="text-center text-gray-500 py-4 text-sm"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;

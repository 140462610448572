// Form.jsx
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { Loading } from "..";
import useApi from "../../hooks/useApi";

const Form = ({ item, closeModal, setData }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    postalAddress: "",
    physicalAddress: "",
    department: "",
    employeeStatus: "",
    workingHours: "",
    gender: "",
    dob: "",
    dateOfEntry: "",
    name: "",
    email: "",
    guardianPhone: "",
    guardianPhysicalAddress: "",
  });

  const insertApi = useApi();
  const updateApi = useApi();

  useEffect(() => {
    if (item) {
      setFormData({
        id: item.id || "",
        fullname: item.fullname || "",
        phone: item.phone || "",
        postalAddress: item.postalAddress || "",
        physicalAddress: item.physicalAddress || "",
        department: item.department || "",
        employeeStatus: item.employeeStatus || "",
        workingHours: item.workingHours || "",
        gender: item.gender || "",
        dob: item.dob || "",
        dateOfEntry: item.dateOfEntry || "",
        name: item.EmployeeNextOfKins?.[0]?.name || "", // Optional chaining to prevent undefined error
        email: item.email || "",
        guardianPhone: item.EmployeeNextOfKins?.[0]?.phone || "",
        guardianPhysicalAddress:
          item.EmployeeNextOfKins?.[0]?.physicalAddress || "",
      });
    }
  }, [item]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.fullname) formErrors.fullname = "Fullname is required";
    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\+?\d{1,14}$/.test(formData.phone)) {
      formErrors.phone = "Invalid phone number (e.g +265996570252)";
    }

    // Check if dateRegistered is provided
    if (!formData.dateOfEntry) {
      formErrors.dateOfEntry = "Date joined is required";
    } else {
      // Parse the input date and remove time component
      const inputDate = new Date(formData.dateOfEntry);
      inputDate.setHours(0, 0, 0, 0); // Normalize the time to 00:00:00

      // Get today's date and remove the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

      // Check if the input date is in the future (but allow today)
      if (inputDate > today) {
        formErrors.dateOfEntry = "Date joined cannot be in the future";
      }
    }
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.department) formErrors.department = "Department is required";
    if (!formData.workingHours)
      formErrors.workingHours = "Working hours is required";
    if (!formData.employeeStatus)
      formErrors.employeeStatus = "Employee type is required";
    if (!formData.dob) formErrors.dob = "Date of birth is required";
    if (!formData.physicalAddress)
      formErrors.physicalAddress = "Physical address is required";

    return formErrors;
  };

  const handleInsert = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      await insertApi.refetch(
        `${apiUrl}/staff`,
        "POST",
        { ...formData, date: new Date().toLocaleDateString() },
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err.response ? err.response.data.error : err.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: `<b>Staff: ${data?.emp?.fullname} added successfully</b>`,
            });

            setData((prev) => {
              // Check if prev is an array or initialize it as an empty array
              return Array.isArray(prev)
                ? [
                    { ...data?.emp, EmployeeNextOfKins: [data?.nextOfKin] },
                    ...prev,
                  ]
                : [data];
            });

            setErrors({});
            setFormData({
              fullname: "",
              phone: "",
              postalAddress: "",
              physicalAddress: "",
              department: "",
              employeeStatus: "",
              workingHours: "",
              gender: "",
              dob: "",
              dateOfEntry: "",
              name: "",
              email: "",
              guardianPhone: "",
              guardianPhysicalAddress: "",
            });
          }
        }
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(Object.values(validationErrors).join(", ")),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px",
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      await updateApi.refetch(
        `${apiUrl}/staff/${item.id}`,
        "PUT",
        formData,
        { headers: { loginToken: localStorage.getItem("loginToken") } },
        (err, data) => {
          if (err) {
            Swal.fire({
              title: "Form Error!",
              html: err,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              icon: "success",
              width: "400px",
              customClass: {
                popup: "small-swal",
              },
              html: "<b>Staff has been updated successfully</b>",
            });

            setData((prev) => {
              return prev.map((c) =>
                c.id === item.id
                  ? {
                      ...c,
                      fullname: formData.fullname,
                      gender: formData.gender,
                      department: formData.department,
                      phone: formData.phone,
                      postalAddress: formData.postalAddress,
                      physicalAddress: formData.physicalAddress,
                      workingHours: formData.workingHours,
                      employeeStatus: formData.employeeStatus,
                      dateOfEntry: formData.dateOfEntry,
                      dob: formData.dob,
                      email: formData.email,
                      EmployeeNextOfKins: [
                        {
                          employee: item.id,
                          name: formData.name,
                          phone: formData.guardianPhone,
                          physicalAddress: formData.guardianPhysicalAddress,
                        },
                      ],
                    }
                  : c
              );
            });

            closeModal();
          }
        }
      );
    }
  };

  // Combined loading state
  const loading = insertApi.loading || updateApi.loading;

  return (
    <form className="w-full md:p-8 bg-white rounded">
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {item ? "Updating Staff" : "Staff Form"}
      </h2>

      {loading && <Loading />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="fullname">
            Fullname <span className="text-red-500">*</span>
          </label>
          <input
            id="fullname"
            name="fullname"
            type="text"
            placeholder="e.g Mike Mhango"
            value={formData.fullname}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.fullname ? "border-red-500" : "border-black"
            }`}
          />
          {errors.fullname && (
            <p className="text-red-500 text-xs mt-1">{errors.fullname}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="gender">
            Gender <span className="text-red-500">*</span>
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.gender ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors.gender && (
            <p className="text-red-500 text-xs mt-1">{errors.gender}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="phone">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="e.g +265996570252"
            value={formData.phone}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.phone ? "border-red-500" : "border-black"
            }`}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="e.g mikelibamba@gmail.com"
            value={formData.email}
            onChange={handleChange}
            className={`w-full p-2 border rounded border-black`}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="postalAddress"
          >
            Postal Address
          </label>
          <input
            id="postalAddress"
            name="postalAddress"
            type="text"
            value={formData.postalAddress}
            onChange={handleChange}
            className="w-full p-2 border rounded border-black"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="physicalAddress"
          >
            Physical Address <span className="text-red-500">*</span>
          </label>
          <input
            id="physicalAddress"
            name="physicalAddress"
            placeholder="e.g Mchesi, Lilongwe"
            type="text"
            value={formData.physicalAddress}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.physicalAddress ? "border-red-500" : "border-black"
            }`}
          />
          {errors.physicalAddress && (
            <p className="text-red-500 text-xs mt-1">
              {errors.physicalAddress}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="dateOfEntry">
            Date Joined <span className="text-red-500">*</span>
          </label>
          <input
            id="dateOfEntry"
            name="dateOfEntry"
            type="date"
            value={formData.dateOfEntry}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dateOfEntry ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dateOfEntry && (
            <p className="text-red-500 text-xs mt-1">{errors.dateOfEntry}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="dob">
            Date of Birth<span className="text-red-500">*</span>
          </label>
          <input
            id="dob"
            name="dob"
            type="date"
            value={formData.dob}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dob ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dob && (
            <p className="text-red-500 text-xs mt-1">{errors.dob}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="department">
            Department <span className="text-red-500">*</span>
          </label>
          <select
            id="department"
            name="department"
            value={formData.department}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.department ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Department</option>
            <option value="Administration">Administration</option>
            <option value="Accounts">Accountant</option>
            <option value="Instructors">Instructor</option>
            <option value="Driver">Driver</option>
            <option value="Reception">Receptionist</option>
            <option value="Cleaner">Cleaner</option>
          </select>
          {errors.department && (
            <p className="text-red-500 text-xs mt-1">{errors.department}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="employeeStatus"
          >
            Staff type <span className="text-red-500">*</span>
          </label>
          <select
            id="employeeStatus"
            name="employeeStatus"
            value={formData.employeeStatus}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.employeeStatus ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Type</option>
            <option value="Full Time">Full Time</option>
            <option value="Part Time">Part Time</option>
          </select>
          {errors.employeeStatus && (
            <p className="text-red-500 text-xs mt-1">{errors.employeeStatus}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-bold mb-2"
            htmlFor="workingHours"
          >
            Working Hours <span className="text-red-500">*</span>
          </label>
          <input
            id="workingHours"
            name="workingHours"
            placeholder="Number of working hours"
            type="number"
            value={formData.workingHours}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.workingHours ? "border-red-500" : "border-black"
            }`}
          />
          {errors.workingHours && (
            <p className="text-red-500 text-xs mt-1">{errors.workingHours}</p>
          )}
        </div>

        {/* Guardian Information */}
        <div className="mb-4 col-span-1 md:col-span-2 lg:col-span-3 grid  gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <h3 className="text-lg font-semibold mb-2 lg:col-span-3 md:col-span-2 col-span-1">
            Next of kin information
          </h3>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="guardianName"
            >
              Guardian Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.name ? "border-red-500" : "border-black"
              }`}
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="guardianPhone"
            >
              Guardian Phone
            </label>
            <input
              id="guardianPhone"
              name="guardianPhone"
              type="text"
              value={formData.guardianPhone}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.guardianPhone ? "border-red-500" : "border-black"
              }`}
            />
            {errors.guardianPhone && (
              <p className="text-red-500 text-xs mt-1">
                {errors.guardianPhone}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="guardianPhysicalAddress"
            >
              Physical Address
            </label>
            <input
              id="guardianPhysicalAddress"
              name="guardianPhysicalAddress"
              type="text"
              value={formData.guardianPhysicalAddress}
              onChange={handleChange}
              className={`w-full p-2 border rounded ${
                errors.guardianPhysicalAddress
                  ? "border-red-500"
                  : "border-black"
              }`}
            />
            {errors.guardianPhysicalAddress && (
              <p className="text-red-500 text-xs mt-1">
                {errors.guardianPhysicalAddress}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="px-2 flex gap-6">
        {!item ? (
          <button
            type="button"
            onClick={handleInsert}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Submit"}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleUpdate}
            disabled={loading}
            className={`mt-6 ${
              loading ? "cursor-not-allowed" : ""
            } bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6`}
          >
            {loading ? "Processing ..." : "Update"}
          </button>
        )}

        <div
          type="button"
          onClick={closeModal}
          className="mt-6 py-2 cursor-pointer px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default Form;

import React, { useEffect } from "react";
import { BiDownload } from "react-icons/bi";
import html2pdf from "html2pdf.js";
import { readableTimestamp } from "./../../utils/readableTimeStamps";
import { QuickPassLogo } from "..";

const Report = ({ data, dates, searchTerm, closeModal }) => {
  const print = (id) => {
    const element = document.getElementById(id);
    html2pdf(element, {
      margin: 2, // Reduced margin for fitting more content
      filename: `test-report from ${dates.startDate} to ${
        dates.endDate
      } _${readableTimestamp()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 }, // Higher scale for quality
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Portrait A4
    });
  };

  useEffect(() => {
    print("report-content");
    closeModal();
  }, []);

  return (
    <div className="container mx-auto p-6 gap-6 flex flex-col">
      <button
        className="flex items-center justify-center gap-1 flex-wrap"
        onClick={() => print("report-content")}
      >
        <BiDownload size={20} />
        Download Report
      </button>
      {/* Tables Container */}
      <div className="w-full flex flex-col gap-[20px]" id="report-content">
        <div className="mb-8 flex md:justify-between justify-center items-center flex-wrap gap-3 w-full">
          <div>
            <QuickPassLogo />
            <p className="text-sm mt-2">
              Date range:.{" "}
              {"From " + dates.startDate + " to " + dates.endDate || "N/A"}
            </p>
            {searchTerm && (
              <p className="text-sm mt-2">With search term:. {searchTerm}</p>
            )}
            <p className="text-sm mt-1">
              Date: {new Intl.DateTimeFormat("en-GB").format(new Date())}
            </p>
            <p className="text-sm mt-1">Total records: {data.length}</p>
          </div>
          <h2 className="text-xl font-bold mb-4 text-center">Test Report</h2>

          {/* Table */}
          <div className="overflow-x-auto mt-4 md:pr-2 pr-1 w-full">
            <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="px-4 py-2">Date of test</th>
                  <th className="px-4 py-2">Student</th>
                  <th className="px-4 py-2">Test name</th>
                  <th className="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="text-center hover:bg-slate-200">
                    <td className="px-4 py-2">{item?.dateOfTest}</td>
                    <td className="px-4 py-2">{item?.Student?.fullname}</td>
                    <td className="px-4 py-2">{item?.testName}</td>
                    <td
                      className={`"px-4 py-2 capitalize border-[1px] border-white text-white " ${
                        item?.passOrFail === "pass"
                          ? "bg-green-600 "
                          : "bg-red-600"
                      }`}
                    >
                      {item?.passOrFail}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;

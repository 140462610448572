// Import React and necessary components from react-chartjs-2
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

// PieChart component
const PieChart = ({ date, fees, income, payments, gvt }) => {
  const [totalFees, setTotalFees] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);

  useEffect(() => {
    var allAmounts = [];
    allAmounts = fees.map((item) => item.amount);
    const sumOfFees = allAmounts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    allAmounts = income.map((item) => item.amount);
    const sumOfIncome = allAmounts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    allAmounts = gvt.map((item) => item.amount);
    const sumOfGvt = allAmounts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    setTotalFees(sumOfFees + sumOfIncome + sumOfGvt);

    allAmounts = payments.map((item) => item.amount);
    const sumOfPayments = allAmounts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    setTotalPayment(sumOfPayments);
  }, []);

  const data = {
    labels: ["Income", "Expenses"],
    datasets: [
      {
        label: "Amount",
        data: [totalFees, totalPayment], // Your data values
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // Return the chart component
  return (
    <div className="mt-[50px] mx-[5px] flex flex-col items-center">
      <h2 className="mb-4 text-center pl-2">Income Vs Expenses for {date}</h2>
      <Pie data={data} />
    </div>
  );
};

export default PieChart;

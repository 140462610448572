import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../utils/links";
import Loading from "../Loading";
import StudentReceipts from "./StudentReceipts";
import { StudentBasicInfo } from "..";
import { BiPrinter, BiRefresh } from "react-icons/bi";

const FeesHistory = ({ student, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [tempStudent, setTempStudent] = useState({});
  const [error, setError] = useState({});
  const [refresher, setRefresher] = useState(Date.now());

  useEffect(() => {
    setLoading(true);
    fetchFullInfo(student.studentID);
  }, [refresher]);

  function refresh() {
    setRefresher(Date.now());
  }

  const fetchFullInfo = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/students/${id}`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });
      if (response.data) {
        setTempStudent(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-full md:p-8 bg-white rounded flex flex-col">
      <h2 className="text-2xl font-semibold mb-6">
        Student Traffic Fees History
      </h2>
      <div className="self-end mb-1 flex items-center gap-2">
        <button
          className="flex font-bold items-center text-sm gap-1 "
          onClick={refresh}
        >
          {" "}
          <BiRefresh size={22} />
          Refresh
        </button>
        <div className="px-4 py-2 text-sm font-bold flex items-center bg-gray-500 text-white rounded-md gap-1 cursor-pointer">
          <BiPrinter size={22} />
          Print
        </div>
      </div>

      <StudentBasicInfo
        student={student}
        tempStudent={tempStudent}
        caller={"RT"}
      />

      <StudentReceipts
        student={tempStudent}
        setStudent={setTempStudent}
        fetchFullInfo={fetchFullInfo}
      />

      <div
        type="button"
        disabled={loading}
        onClick={closeModal}
        className="w-[200px] cursor-pointer self-end mt-6 py-2 px-4 mb-6 bg-red-500 rounded-md text-center font-bold text-white"
      >
        Close
      </div>
    </div>
  );
};

export default FeesHistory;

import React, { useEffect, useState } from "react";
import { BiData } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "./../hooks/useApi";
import { apiUrl } from "../utils/links";
import { PaymentForm, PaymentPaginated, Loading, Modal } from "./../components";
import { CgAdd } from "react-icons/cg";

const Payment = () => {
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const start = new Date("2013-01-01").toISOString().split("T")[0]; // Set start date in the correct format

  const [dates, setDates] = useState({
    startDate: start,
    endDate: today,
  });
  const [errors, setErrors] = useState({});
  const { data, error, setData, loading, refetch } = useApi();

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDates((prevDates) => {
      const updatedDates = { ...prevDates, [name]: value };

      // Automatically update endDate when startDate is changed
      if (name === "startDate") {
        updatedDates.endDate = value;
      }

      return updatedDates;
    });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const validate = () => {
    let formErrors = {};

    if (!dates.startDate) {
      formErrors.startDate = "Start date is required";
    }

    if (!dates.endDate) {
      formErrors.endDate = "End date is required";
    }

    if (
      dates.startDate &&
      dates.endDate &&
      new Date(dates.startDate) > new Date(dates.endDate)
    ) {
      formErrors.endDate =
        "End date should be greater than or equal to start date";
    }

    return formErrors;
  };

  const handleCreatePayment = () => {
    setModalContent(<PaymentForm closeModal={closeModal} />);
    openModal();
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  const handleLoadData = async () => {
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setErrors({});
      await refetch(
        `${apiUrl}/payments/${dates.startDate}/${dates.endDate}`,
        "GET",
        null,
        { headers: { loginToken: localStorage.getItem("loginToken") } }
      );
    }
  };

  return (
    <div className="w-full flex h-full flex-col items-center relative gap-3">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Payment
      </div>
      <div className="text-lg font-bold mt-[20px]">Manage Payment</div>
      <div className="w-full flex lg:flex-row  items-center flex-col gap-4 justify-start md:gap-[15px] mt-3 border-b-2 border-b-gray-300 rounded-lg bg-gray-100 p-2">
        <div
          onClick={handleCreatePayment}
          className="flex h-[40px] flex-row gap-1 items-center py-2 px-4 rounded-md shadow-md cursor-pointer bg-gray-500 text-white animate-bounce"
        >
          <CgAdd size={20} />
          Add Payment
        </div>
        <div className="relative flex flex-col items-center">
          <label htmlFor="startDate">Start date</label>
          <input
            type="date"
            name="startDate"
            value={dates.startDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.startDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select start date"
          />
          {errors.startDate && (
            <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
          )}
        </div>
        <div className="relative flex flex-col items-center">
          <label htmlFor="endDate">End date</label>
          <input
            type="date"
            name="endDate"
            value={dates.endDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.endDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select end date"
          />
          {errors.endDate && (
            <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>
          )}
        </div>
        <div
          onClick={handleLoadData}
          className="cursor-pointer ml-2 lg:self-end p-[7px] gap-1 shadow-xl bg-[var(--primary-dark)] px-4 rounded-md text-white font-semibold flex flex-row items-center"
        >
          <BiData size={20} />
          Get data/Refresh
        </div>
      </div>
      <div className="flex flex-wrap items-center flex-col w-full">
        <div className="w-full">
          {loading ? (
            <Loading />
          ) : error ? (
            <div className="w-full text-center text-lg text-red-600 mt-[40px]">
              Oops! {error}
            </div>
          ) : data ? (
            <PaymentPaginated data={data} setData={setData} />
          ) : (
            <div className="w-full text-center mt-[40px] ">
              <p className="text-lg">Nothing to display here.</p>
              <p className="mt-[15px] italic">
                Please load the data by selecting the date-range above
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;

import React, { useContext, useState } from "react";
import { BiHistory, BiHome, BiReceipt, BiSearch, BiTrafficCone, BiUser } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { FaBalanceScale, FaCar, FaThumbsUp } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdPayments } from "react-icons/md";
import { PiStudent } from "react-icons/pi";
import { SiCoursera } from "react-icons/si";
import { TbEyeStar, TbReport } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/Contexts";

const links = [
  { name: "Dashboard", icon: BiHome, to: "/dashboard" },
  { name: "Students", icon: PiStudent, to: "/student" },
  {
    name: "Courses & Durations",
    icon: SiCoursera,
    to: "/course",
    adminOnly: true,
  },
  { name: "Staff", icon: BsPeople, to: "/staff", adminOnly: true },
  { name: "Vehicles", icon: FaCar, to: "/vehicle", teacherRestricted: true },
  { name: "Traffic Fees", icon: BiTrafficCone, to: "/trafficfees", adminOnly: true },
  { name: "divider", teacherRestricted: true },
  {
    name: "Receipts",
    icon: BiReceipt,
    to: "/receipts",
    teacherRestricted: true,
  },
  {
    name: "Other Income",
    icon: FaMoneyBillTransfer,
    to: "/income",
    teacherRestricted: true,
  },
  {
    name: "Payments",
    icon: MdPayments,
    to: "/payment",
    teacherRestricted: true,
  },
  {
    name: "Fees Balances",
    icon: FaBalanceScale,
    to: "/feesBalance",
    teacherRestricted: true,
  },
  {
    name: "Financial Report",
    icon: TbReport,
    to: "/financialReport",
    teacherRestricted: true,
  },
  { name: "divider" },
  { name: "Driving Test", icon: TbEyeStar, to: "/test" },
  { name: "Vehicle Allocation", icon: FaThumbsUp, to: "/allocation" },
  { name: "divider", className: "border-red-600/80", adminOnly: true },
  { name: "System Users", icon: BiUser, to: "/user", adminOnly: true },
  { name: "Login History", icon: BiHistory, to: "/trail", adminOnly: true },
];

const SideNavBar = ({ handleToggleSideNav, toggleSideNav }) => {
  const [search, setSearch] = useState("");
  const { authUser } = useContext(AuthContext);
  const location = useLocation();

  const filteredLinks = links.filter((link) => {
    if (link.adminOnly && authUser.role !== "admin") {
      return false; // Hide admin-only links if user is not an admin
    }
    if (link.teacherRestricted && authUser.role === "Teacher") {
      return false; // Hide teacher-restricted links for teachers
    }
    return link.name?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div>
      <div className="md:fixed h-[92vh] overflow-auto w-[295px] shadow-2xl gap-[25px] flex-col md:flex hidden px-3 mt-[10px] items-center">
        <div className="mt-[30px] relative">
          <BiSearch className="absolute mt-[10px] ml-[8px]" size={24} />
          <input
            type="text"
            name="search"
            id="search"
            className="p-2 border-2 rounded-md pl-[35px]"
            placeholder="Quick Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <ul className="self-start flex flex-col gap-1 mb-5">
          {filteredLinks.length > 0 ? (
            filteredLinks.map((link, index) => (
              <li key={index}>
                {link.name === "divider" ? (
                  <hr
                    className={`my-1 ${link.className || "border-gray-300"}`}
                  />
                ) : (
                  <Link
                    className={`flex items-center gap-3 ${
                      location.pathname === link.to
                        ? "text-black font-bold  animate-bounce"
                        : ""
                    }`}
                    to={link.to}
                  >
                    <link.icon
                      size={20}
                      className="text-[var(--primary-dark)]"
                    />
                    <span className="text-[11pt]">{link.name}</span>
                  </Link>
                )}
              </li>
            ))
          ) : (
            <li className="text-gray-500 text-[11pt]">Not found!</li>
          )}
        </ul>
      </div>

      <div className="md:hidden flex flex-col">
        {toggleSideNav && (
          <div className="h-[92vh] absolute top-5 z-10 bg-white overflow-auto shadow-2xl gap-[25px] flex-col flex md:hidden px-3 mt-[10px] items-center">
            <div className="mt-[30px] relative">
              <BiSearch className="absolute mt-[10px] ml-[8px]" size={24} />
              <input
                type="text"
                name="search"
                id="search"
                className="p-2 border-2 rounded-md pl-[35px]"
                placeholder="Quick Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <ul className="self-start flex flex-col gap-1 mb-5">
              {filteredLinks.length > 0 ? (
                filteredLinks.map((link, index) => (
                  <li key={index}>
                    {link.name === "divider" ? (
                      <hr
                        className={`my-1 ${
                          link.className || "border-gray-300"
                        }`}
                      />
                    ) : (
                      <Link
                        onClick={handleToggleSideNav}
                        className={`flex items-center gap-3 ${
                          location.pathname === link.to
                            ? "text-black font-bold  animate-bounce"
                            : ""
                        }`}
                        to={link.to}
                      >
                        <link.icon
                          size={20}
                          className="text-[var(--primary-dark)]"
                        />
                        <span className="text-[11pt]">{link.name}</span>
                      </Link>
                    )}
                  </li>
                ))
              ) : (
                <li className="text-gray-500 text-[11pt]">Not found!</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNavBar;

import React, { useContext, useEffect } from "react";
import {
  DashCard,
  Loading,
  StatisticCard,
  Chart,
  GroupedPayments,
  Pie,
} from "./../components";
import { Link } from "react-router-dom";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import { AuthContext } from "../context/Contexts";

const Dashboard = () => {
  const studentApi = useApi();
  const courseApi = useApi();
  const receiptApi = useApi();
  const feesApi = useApi();
  const incomeApi = useApi();
  const paymentsApi = useApi();
  const gvtApi = useApi();
  const { authUser } = useContext(AuthContext);

  const startOfMonth = "01";
  const endOfMonth = "31";
  const currentMonth = new Date().getMonth() + 1; // Adjusted for correct month index
  const currentYear = new Date().getFullYear();
  const startDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-${startOfMonth}`;
  const endDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-${endOfMonth}`;

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        studentApi.refetch(`${apiUrl}/students`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
        courseApi.refetch(`${apiUrl}/courses/all/withStudents`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
        receiptApi.refetch(`${apiUrl}/receipts/grouped/get/all`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
        feesApi.refetch(
          `${apiUrl}/receipts/${startDate}/${endDate}`,
          "GET",
          null,
          {
            headers: { loginToken: localStorage.getItem("loginToken") },
          }
        ),
        incomeApi.refetch(
          `${apiUrl}/income/${startDate}/${endDate}`,
          "GET",
          null,
          {
            headers: { loginToken: localStorage.getItem("loginToken") },
          }
        ),
        paymentsApi.refetch(
          `${apiUrl}/payments/${startDate}/${endDate}`,
          "GET",
          null,
          {
            headers: { loginToken: localStorage.getItem("loginToken") },
          }
        ),
        gvtApi.refetch(`${apiUrl}/gvt/${startDate}/${endDate}`, "GET", null, {
          headers: { loginToken: localStorage.getItem("loginToken") },
        }),
      ]);
    };

    loadData();
  }, []);

  const loading =
    studentApi.loading ||
    courseApi.loading ||
    receiptApi.loading ||
    feesApi.loading ||
    incomeApi.loading ||
    gvtApi.loading ||
    paymentsApi.loading;
  const errors =
    studentApi.error ||
    courseApi.error ||
    receiptApi.error ||
    feesApi.error ||
    incomeApi.error ||
    gvtApi.error ||
    paymentsApi.error;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const studentsInDateRange = studentApi.data?.filter((student) => {
    const dateRegistered = new Date(student.dateRegistered);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return dateRegistered >= start && dateRegistered <= end;
  }).length;

  return (
    <div className="w-full h-full rounded-lg gap-3 flex items-start flex-col shadow-md mt-[10px] mb-[40px]">
      <h1 className="md:text-[16pt] text-[14pt] font-light self-center">
        Pamseu Dashboard
      </h1>
      <hr className="w-full" />
      {errors && <div>{JSON.stringify(errors)}</div>}
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap justify-evenly w-full gap-6">
          <DashCard title={"Totals statistics"}>
            <Link to={"/student"}>
              <StatisticCard
                name="Total Students ~ "
                value={studentApi.data?.length}
              />
              <StatisticCard
                name={`${monthNames[currentMonth - 1]} Students`}
                value={studentsInDateRange}
              />
            </Link>
          </DashCard>
          <DashCard title={`Active statistics`}>
            <Link to={"/student"}>
              <StatisticCard
                name="Pending Students ~ "
                value={
                  studentApi.data?.filter((item) => item.graduated === 0).length
                }
              />
            </Link>
            <Link to={"/student"}>
              <StatisticCard
                name="Graduated students ~ "
                value={
                  studentApi.data?.filter((item) => item.graduated === 1).length
                }
              />
            </Link>
          </DashCard>
          <DashCard title={"Gender statistics"}>
            <Link to={"/student"}>
              <StatisticCard
                name="Males ~ "
                value={
                  studentApi.data?.filter((item) => item.gender === "male")
                    .length
                }
              />
            </Link>
            <Link to={"/student"}>
              <StatisticCard
                name="Females ~ "
                value={
                  studentApi.data?.filter((item) => item.gender === "female")
                    .length
                }
              />
            </Link>
          </DashCard>

          <Chart data={courseApi.data || []} />

          {authUser?.role !== "Teacher" && (
            <Pie
              date={`${monthNames[new Date().getMonth()]} ${currentYear}`}
              fees={feesApi.data || []}
              income={incomeApi.data || []}
              gvt={gvtApi.data || []}
              payments={paymentsApi.data || []}
            />
          )}

          {authUser?.role !== "Teacher" && (
            <GroupedPayments data={receiptApi?.data || []} />
          )}

          <div className="my-[40px]">
            <hr />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

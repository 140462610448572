import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../utils/links";
import {
  AddTest,
  FeesAdd,
  FeesHistory,
  Loading,
  Modal,
  Paginated,
  RTFeesAdd,
  RTFeesHistory,
} from "./../";
import Swal from "sweetalert2";

const StudentList = ({ setActiveTab }) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleContextMenu, setToggleContextMenu] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchStudents();
  }, []);

  const handleExplore = (student) => {
    console.log("Exploring student:", student);
    setActiveTab({
      tab: 2,
      data: student,
    });
  };

  const handleUpdate = (student) => {
    setActiveTab({ tab: 0, data: student });
  };

  const handleCreate = (student) => {
    setActiveTab({ tab: 0 });
  };

  const handleDelete = (student) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${student?.fullname}?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .delete(`${apiUrl}/students/${student.studentID}`, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          })
          .then((res) => {
            setStudents((prevStudents) =>
              prevStudents.filter((s) => s.studentID !== student.studentID)
            );
            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Student has been successfully deleted.",
              icon: "success",
              timer: 2000,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
              showConfirmButton: false, // Automatically close after 2 seconds
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              Swal.fire({
                title: "Error!",
                text: err.response.data.error,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            }
          });
      }
    });
  };

  const handlePayFees = (student) => {
    setModalContent(<FeesAdd student={student} closeModal={closeModal} />);
    openModal();
  };

  const handleFeesHistory = (student) => {
    setModalContent(<FeesHistory student={student} closeModal={closeModal} />);
    openModal();
  };

  const handlePayTRFees = (student) => {
    if (student?.anyGovernmentFee) {
      setModalContent(<RTFeesAdd student={student} closeModal={closeModal} />);
      openModal();
    } else {
      Swal.fire({
        title: "Unable to make payment",
        text: "This student has no government fee (Self Paid to Road Traffic).",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    }
  };

  const handleTRHistory = (student) => {
    if (student?.anyGovernmentFee) {
      setModalContent(
        <RTFeesHistory student={student} closeModal={closeModal} />
      );
      openModal();
    } else {
      Swal.fire({
        title: "Unable to get history",
        text: "This student has no government fee (Self Paid to Road Traffic).",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    }
  };

  const handleRecordTest = (student) => {
    setModalContent(<AddTest student={student} closeModal={closeModal} />);
    openModal();
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${apiUrl}/students`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });

      if (response.data) {
        setStudents(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex justify-center flex-col">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full h-full">
          {error ? (
            <div className=" text-lg font-bold w-full h-full flex items-center justify-center animate-pulse">
              <div className="text-red-500 px-4 py-2   border-red-500  border-2 ">
                {error}
              </div>
            </div>
          ) : (
            <Paginated
              data={students}
              handleExplore={handleExplore}
              handleFeesHistory={handleFeesHistory}
              handlePayFees={handlePayFees}
              handleRecordTest={handleRecordTest}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              handlePayTRFees={handlePayTRFees}
              handleRTHistory={handleTRHistory}
              toggleContextMenu={toggleContextMenu}
              setToggleContextMenu={setToggleContextMenu}
            />
          )}
        </div>
      )}
      <div className="flex flex-col mt-[300px] w-full items-center">
        <hr />
        <p className="text-sm text-gray-400">pamseu ims v.2.0.1</p>
      </div>
    </div>
  );
};

export default StudentList;

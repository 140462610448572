import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const ChartDiagram = ({ data }) => {
  // Extracting agent names and number of employees
  const chartData = data?.map((item) => ({
    code: item.code,
    students: item?.Students?.length,
  }));

  return (
    <div className="container mx-auto mt-[70px] overflow-auto">
      <h2 className="w-full md:text-xl text-lg p-2 text-center bg-slate-300 font-semibold">
        Courses with Students
      </h2>

      <div className="w-full overflow-auto mt-[50px] container mx-auto flex gap-3 flex-col">
        <BarChart
          width={1080}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="code" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="students" fill="#8884d8" />
        </BarChart>
      </div>
    </div>
  );
};

export default ChartDiagram;

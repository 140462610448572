import React from "react";
import { BiEdit, BiTrash } from "react-icons/bi";

const Info = ({ item, closeModal, handleDelete, handleUpdate }) => {
  return (
    <div className="md:p-6 p-2 max-w-3xl mx-auto bg-white shadow-md rounded-md border border-gray-200">
      <div className="flex md:flex-row flex-col justify-between items-center pb-2">
        <h2 className="md:text-2xl font-bold text-gray-800">
          Employee Information
        </h2>
        <div className="flex flex-wrap items-center gap-2">
          <BiEdit
            title="Update"
            size={24}
            className="text-blue-600 cursor-pointer"
            onClick={() => handleUpdate(item)}
          />{" "}
          <BiTrash
            title="Delete"
            className="text-red-700 cursor-pointer"
            size={24}
            onClick={() => handleDelete(item)}
          />
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div>
          <span className="font-semibold text-gray-600">Full Name:</span>{" "}
          {item.fullname}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Phone:</span>{" "}
          {item.phone}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Postal Address:</span>{" "}
          {item.postalAddress}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Physical Address:</span>{" "}
          {item.physicalAddress}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Department:</span>{" "}
          {item.department}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Employee Status:</span>{" "}
          {item.employeeStatus}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Working Hours:</span>{" "}
          {item.workingHours} hours
        </div>
        <div>
          <span className="font-semibold text-gray-600">Date of Entry:</span>{" "}
          {item.dateOfEntry}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Date:</span> {item.date}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Gender:</span>{" "}
          {item.gender}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Date of Birth:</span>{" "}
          {item.dob}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Email:</span>{" "}
          {item.email ? item.email : "N/A"}
        </div>
        <div>
          <span className="font-semibold text-gray-600">Active Status:</span>{" "}
          {item.isActive ? "Active" : "Inactive"}
        </div>
      </div>

      <div className="mt-6 w-full">
        <h3 className="md:text-xl text-lg font-semibold mb-2 text-gray-700">
          Next of Kin
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {item.EmployeeNextOfKins.map((kin) => (
            <div key={kin?.id} className="p-4 bg-gray-50 border rounded-md w-full">
              <p>
                <span className="font-semibold text-gray-600">Name:</span>{" "}
                {kin?.name ? kin.name : "N/A"}
              </p>
              <p>
                <span className="font-semibold text-gray-600">Phone:</span>{" "}
                {kin?.phone ? kin.phone : "N/A"}
              </p>
              <p>
                <span className="font-semibold text-gray-600">
                  Physical Address:
                </span>{" "}
                {kin?.physicalAddress ? kin.physicalAddress : "N/A"}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={closeModal}
        className="mt-6 cursor-pointer px-4 py-2 bg-red-600 text-white rounded-lg shadow-md text-center font-bold"
      >
        Close
      </div>
    </div>
  );
};

export default Info;

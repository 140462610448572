import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../../utils/links";
import { Loading, StudentBasicInfo } from "..";

const TestAdd = ({ student, closeModal, test, handleLoadData }) => {
  const [formData, setFormData] = useState({
    student: student.studentID,
    testName: "",
    dateOfTest: "",
    passOrFail: "",
  });

  useEffect(() => {
    if (test) {
      setFormData({
        id: test.id,
        student: test.student,
        testName: test.testName,
        dateOfTest: test.dateOfTest,
        passOrFail: test.passOrFail,
      });
    }
  }, []);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tempStudent, setTempStudent] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setFormData((prev) => ({ ...prev, studentID: student.studentID }));
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchFullInfo(student.studentID);
  }, []);

  const fetchFullInfo = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/students/${id}`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });
      if (response.data) {
        setTempStudent(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};

    // Check if dateOfPayment is provided
    if (!formData.dateOfTest) {
      formErrors.dateOfTest = "Date of test is required";
    } else {
      // Parse the input date and remove time component
      const inputDate = new Date(formData.dateOfTest);
      inputDate.setHours(0, 0, 0, 0); // Normalize the time to 00:00:00

      // Get today's date and remove the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

      // Check if the input date is in the future (but allow today)
      if (inputDate > today) {
        formErrors.dateOfTest = "Date of test cannot be in the future";
      }
    }

    if (!formData.testName) formErrors.testName = "Test name is required";
    if (!formData.passOrFail) formErrors.passOrFail = "Status is required";

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButtonText = e.nativeEvent.submitter.innerText;

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setLoading(true);

      if (submitButtonText === "Submit") {
        try {
          const response = await axios.post(`${apiUrl}/tests`, formData, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          });

          fetchFullInfo(student.studentID);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: `<b>Test for ${student?.fullname} added successfully</b>`,
          });

          // Optionally reset form data
          setFormData({
            student: student.studentID,
            testName: "",
            dateOfTest: "",
            passOrFail: "",
          });
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      } else if (submitButtonText === "Update") {
        try {
          const response = await axios.put(
            `${apiUrl}/tests/${test.id}`,
            formData,
            {
              headers: {
                loginToken: localStorage.getItem("loginToken"),
              },
            }
          );

          console.log(response);

          Swal.fire({
            title: "success",
            icon: "success",
            width: "400px", // Set custom width
            customClass: {
              popup: "small-swal",
            },
            html: "<b>Test has been updated successfully</b>",
          });

          fetchFullInfo(student.studentID);

          setFormData({
            id: "",
            student: "",
            testName: "",
            dateOfTest: "",
            passOrFail: "",
          });

          if (handleLoadData) {
            handleLoadData();
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          if (error.response) {
            Swal.fire({
              title: "Form Error!",
              html: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          } else {
            Swal.fire({
              title: "Form Error !",
              html: error.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <form className="w-full md:p-8 bg-white rounded" onSubmit={handleSubmit}>
      <h2 className="md:text-2xl text-lg font-semibold mb-6">
        {test ? "Updating Test" : "Student Test Form"}
      </h2>

      {loading ? (
        <Loading />
      ) : (
        <StudentBasicInfo
          student={student}
          tempStudent={tempStudent}
          caller="test"
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="dateOfTest">
            Date of Test <span className="text-red-500">*</span>
          </label>
          <input
            id="dateOfTest"
            name="dateOfTest"
            type="date"
            value={formData.dateOfTest}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.dateOfTest ? "border-red-500" : "border-black"
            }`}
          />
          {errors.dateOfTest && (
            <p className="text-red-500 text-xs mt-1">{errors.dateOfTest}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="testName">
            Name of Test <span className="text-red-500">*</span>
          </label>
          <select
            id="testName"
            name="testName"
            value={formData.testName}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.testName ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Test</option>
            <option value="Aptitude test 1">Aptitude Test 1</option>
            <option value="Aptitude test 2">Aptitude Test 2</option>
            <option value="Road test">Road Test</option>
          </select>
          {errors.testName && (
            <p className="text-red-500 text-xs mt-1">{errors.testName}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="passOrFail">
            Status <span className="text-red-500">*</span>
          </label>
          <select
            id="passOrFail"
            name="passOrFail"
            value={formData.passOrFail}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              errors.passOrFail ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Status</option>
            <option value="pass">Pass</option>
            <option value="fail">Fail</option>
          </select>
          {errors.passOrFail && (
            <p className="text-red-500 text-xs mt-1">{errors.passOrFail}</p>
          )}
        </div>
      </div>

      <div className=" flex gap-2 justify-evenly">
        <button
          type="submit"
          disabled={loading}
          className="mt-6 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mb-6"
        >
          {test ? (
            <div> {loading ? "Processing ..." : "Update"}</div>
          ) : (
            <div> {loading ? "Processing ..." : "Submit"}</div>
          )}
        </button>

        <div
          type="button"
          disabled={loading}
          onClick={closeModal}
          className=" cursor-pointer mt-6 w-full py-2 px-4 mb-6 bg-red-500 rounded-sm text-center font-bold text-white"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

export default TestAdd;

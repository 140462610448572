import React, { useState } from "react";
import { toKwacha } from "../../utils/toKwacha";
import { HiInformationCircle } from "react-icons/hi";
import { BiEdit, BiSearch, BiTrash } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import { CourseAdd, Modal } from "..";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../utils/links";

const Cards = ({ courses, setCourses, setLoading }) => {
  const [activeCourseId, setActiveCourseId] = useState(null); // Track the clicked course
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addCourse = (data) => {
    setModalContent(
      <CourseAdd closeModal={closeModal} setCourses={setCourses} />
    );
    openModal();
  };

  const updateCourse = (course) => {
    setModalContent(
      <CourseAdd
        course={course}
        closeModal={closeModal}
        setCourses={setCourses}
      />
    );
    openModal();
  };

  // Toggle the clicked course info
  const handleInfoClick = (courseId) => {
    setActiveCourseId((prevId) => (prevId === courseId ? null : courseId)); // Toggle the clicked course
  };

  // Search handler for input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter courses by course.code or course.name
  const filteredCourses = courses.filter(
    (course) =>
      course.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = (course) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${course?.code}?`,
      icon: "warning",
      showCancelButton: true, // Shows both 'Yes' and 'No' buttons
      confirmButtonColor: "#d33", // Customize the "Yes" button color (danger style)
      cancelButtonColor: "#3085d6", // Customize the "No" button color
      confirmButtonText: "Yes, delete it!", // Text for the confirm button
      cancelButtonText: "No, cancel",
      width: "400px", // Set custom width
      customClass: {
        popup: "small-swal",
      }, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .delete(`${apiUrl}/courses/${course.id}`, {
            headers: {
              loginToken: localStorage.getItem("loginToken"),
            },
          })
          .then((res) => {
            setCourses((prev) => prev.filter((c) => c.id !== course.id));
            setLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Course has been successfully deleted.",
              icon: "success",
              timer: 2000,
              width: "400px", // Set custom width
              customClass: {
                popup: "small-swal",
              },
              showConfirmButton: false, // Automatically close after 2 seconds
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              Swal.fire({
                title: "Error!",
                text: err.response.data.error,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                width: "400px", // Set custom width
                customClass: {
                  popup: "small-swal",
                },
              });
            }
          });
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="flex w-full md:justify-between md:flex-row flex-col-reverse gap-2 items-center">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ..."
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>

        <div className="text-gray-400 font-semibold">
          (~{filteredCourses.length}~) Records
        </div>

        <div
          className="px-4 py-2 rounded-lg bg-gray-500 text-white flex items-center gap-1 cursor-pointer"
          onClick={addCourse}
        >
          <CgAdd size={20} />
          Add course
        </div>
      </div>
      {filteredCourses.length > 0 ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
          {filteredCourses.map((course) => (
            <div
              key={course.id}
              className="p-3 border-2 shadow-lg rounded-xl flex flex-col justify-between items-center gap-3 relative"
            >
              <div className="absolute top-0 left-0 m-2">
                <p className="text-sm text-gray-300">ID: {course.id}</p>
              </div>
              <h1 className="text-xl mt-2 font-semibold border-b-[2px] border-gray-300 p-2 w-full text-center">
                {course.code}
              </h1>
              <p>{course.name}</p>
              <p className="text-sm text-center">
                Has {course?.CourseDurations.length} durations
              </p>

              {course?.CourseDurations.length > 0 && (
                <HiInformationCircle
                  title="List durations"
                  size={24}
                  onClick={() => handleInfoClick(course.id)}
                  className="cursor-pointer absolute top-0 right-0 m-2 text-[var(--primary-dark)]"
                />
              )}

              <div className="flex justify-center gap-[35px] border-t-[1px] border-red-200 w-full items-center p-1 mt-2">
                <BiEdit
                  size={20}
                  title="Update course"
                  className="cursor-pointer"
                  onClick={() => updateCourse(course)}
                />
                <BiTrash
                  size={20}
                  color="red"
                  title="Delete course"
                  className="cursor-pointer"
                  onClick={() => handleDelete(course)}
                />
              </div>
              {activeCourseId === course.id && (
                <div className="absolute top-14 left-0 z-10 bg-gray-300 shadow-lg p-3 rounded-md">
                  <h3 className="font-semibold">Durations:</h3>
                  {course?.CourseDurations.length > 0 ? (
                    <ul>
                      {course.CourseDurations.map((duration, idx) => (
                        <li key={idx}>
                          Duration: {duration?.Duration.name}, Fees:{" "}
                          {toKwacha(duration.fees)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No durations available</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="m-auto max-w-full text-xl mt-5 font-semibold uppercase">
          No courses found !
        </div>
      )}
    </div>
  );
};

export default Cards;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { apiUrl } from "../../utils/links";
import { DurationCards, Loading, Modal } from "..";

const Landing = ({ setActiveTab }) => {
  const [durations, setDurations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchDurations();
  }, []);

  const fetchDurations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/durations`, {
        headers: {
          loginToken: localStorage.getItem("loginToken"),
        },
      });

      if (response.data) {
        setDurations(response.data);
      }
    } catch (err) {
      if (err.response) setError(err.response.data.error);
      else setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex justify-center flex-col">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full h-full">
          {error ? (
            <div className="text-lg font-bold w-full h-full flex items-center justify-center animate-pulse">
              <div className="text-red-500 px-4 py-2 border-red-500 border-2">
                {error}
              </div>
            </div>
          ) : (
            <DurationCards durations={durations} setDurations={setDurations} fetchDurations={fetchDurations} setLoading={setLoading}/>
          )}
        </div>
      )}
    </div>
  );
};

export default Landing;

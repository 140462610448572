import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CDLanding, CourseLanding, DurationLanding } from "../components";

function Course() {
  const [activeTab, setActiveTab] = useState({
    tab: 0,
    data: null,
  });

  const tabContent = [
    {
      title: "Courses",
      content: <CourseLanding setActiveTab={setActiveTab} />,
    },
    {
      title: "Durations",
      content: (
        <DurationLanding setActiveTab={setActiveTab} activeTab={activeTab} />
      ),
    },
    {
      title: "Fees Structure",
      content: <CDLanding setActiveTab={setActiveTab} activeTab={activeTab} />,
    },
  ];

  // Handle tab change
  const handleTabChange = (index) => {
    setActiveTab({ tab: index });
  };

  return (
    <div className="w-full flex h-full flex-col items-center justify-between relative gap-3">
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Courses & Durations
      </div>
      <div className="text-lg font-bold mt-[20px]">Manage Courses & Durations</div>
      {/* Render tab buttons */}
      <div className="w-full flex flex-wrap lg:justify-evenly md:justify-start md:gap-[15px] gap-1 justify-evenly mt-3 border-b-2 border-b-gray-300 rounded-lg bg-gray-100 p-2">
        {tabContent.map((tab, index) => (
          <>
            {tab.title.length > 0 && (
              <button
                key={index}
                onClick={() => handleTabChange(index)}
                className={
                  activeTab.tab === index
                    ? "border-2 border-black/70 md:text-[12pt] text-[11pt] bg-gray-300 px-3 animate-pulse  text-black/90 p-2 rounded-2xl m-1"
                    : "border-2 shadow-lg border-white rounded-2xl md:text-[11pt] text-[10pt] px-4"
                }
              >
                {tab.title}
              </button>
            )}
          </>
        ))}
      </div>

      {/* Render active tab content */}
      <div className="w-full p-2 flex h-full flex-col md:pr-[45px]">
        {tabContent[activeTab.tab].content}
        <div className="my-[50px]"><hr /></div>
      </div>
    </div>
  );
}

export default Course;
